import { useEffect } from "react";
import { Container } from "../components";
import { Helmet } from "react-helmet";

export const PrivacyPolicy = () => {
  let root = document.getElementById("root");
  useEffect(() => {
    let rootHeight = root.offsetHeight;
    const target = document.getElementById("get_height");
    console.log("called", target);
    // if (!target) return;
    let bodyHeight = window.innerHeight;
    let ownTakenHeight = target?.offsetHeight;

    let restHeight = bodyHeight - rootHeight;
    if (rootHeight < bodyHeight && target) {
      target.style.minHeight = `${restHeight + ownTakenHeight}px`;
    }
    // console.log({ rootHeight, bodyHeight, restHeight, ownTakenHeight });
  }, [root]);
  return (
    <>
    <Helmet>
      <title>Privacy Policy | PollsBoard.com</title>
      <meta name="description" content="Read our privacy policy to understand how PollsBoard.com collects, uses, and protects your personal information." />
      <meta name="keywords" content="privacy policy, personal information, data protection, PollsBoard" />
    </Helmet>
    <section id="card" className="bg-slate-200 py-12">
      <Container>
        <h2 className="text-xl text-center border-b-2 border-gray-800 mb-5 pb-2 text-gray-800">Privacy policy</h2>
        <p className="text-lg mb-5 font-normal text-gray-800">Privacy Policy for PollsBoard.com</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          By using PollsBoard.com, you agree to the terms of this Privacy Policy. If you do not agree to the terms of
          this Privacy Policy, please do not use PollsBoard.com.
        </p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          At PollsBoard.com, we recognize that privacy is important. This Privacy Policy outlines the types of personal
          information we receive and collect when you use PollsBoard.com, as well as some of the steps we take to
          safeguard information. We hope this will help you make an informed decision about sharing personal information
          with us.
        </p>
        <p className="text-lg mb-5 font-normal text-gray-800">Information we collect</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          When you visit PollsBoard.com, we may collect and store the following types of personal information:
        </p>
        <ul style={{ listStyle: "initial", padding: "revert" }} className="mb-5">
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Email address: We collect your email address to create an account on
              our website.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              IP address: We may collect your IP address when you visit our website, in order to diagnose problems with
              our server and to administer our website.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Cookies: We may use cookies to store information about your preferences and to personalize your experience
              on our website.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Poll responses: When you participate in a poll on our website, we collect and store your response.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Birthdate: We collect and store your birthdate to verify your age and to provide age filters.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Driver license or identification card numbers and expiration date: We collect your driver license number and expiration date
              to verify your identity and to prevent account duplications.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Identification card number and expiration date: We collect your identification card number and
              expiration date to verify your identity and to prevent account duplications.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Zip code: We collect your zip code for demographic purposes and to provide poll filters by location.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Information collected via Stripe Identity SaaS: We use the Stripe Identity SaaS service to collect
              information such as your name, date of birth, zip code, and government-issued ID number in order to verify
              your identity.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              ACH information: We may collect ACH information such as your bank account and routing number, for the
              purpose of paying our users for their participation in our paid polls.
            </p>
          </li>
        </ul>
        <p className="text-lg mb-5 font-normal text-gray-800">How we use your information</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          We use the personal information we collect in the following ways:
        </p>
        <ul style={{ listStyle: "initial", padding: "revert" }} className="mb-5">
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To provide and maintain our website, including offering filters functionality to enhance user experience.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To ensure the integrity of our data, including detecting and preventing fraudulent activities,
              spam, or abuse.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To verify user authenticity and uniqueness, such as by confirming the identity of users to
              prevent duplicate accounts or unauthorized access.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To improve our website and services, by analyzing user behavior and preferences,
              which may include aggregating and analyzing anonymized data.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To respond to your inquiries and to provide customer support, including addressing
              technical issues or answering questions about our services.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To comply with legal obligations, such as verifying your age or identity or responding to a subpoena or
              court order.
            </p>
          </li>
        </ul>
        <p className="text-lg mb-5 font-normal text-gray-800">Disclosure of your information</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          We do not sell, rent, or trade your personal information with third parties for their commercial purposes.
          However, we may disclose your personal information in the following circumstances:
        </p>
        <ul style={{ listStyle: "initial", padding: "revert" }} className="mb-5">
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              If required by law, such as in response to a subpoena or court order.
            </p>
          </li>

          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To protect our rights or property, such as in the case of fraud or intellectual property infringement.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              To comply with legal process, such as a government investigation or audit.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              In connection with a merger, acquisition, or other corporate transaction.
            </p>
          </li>
        </ul>
        <p className="text-lg mb-5 font-normal text-gray-800">Security</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.
          However, no transmission of data over the internet is completely secure, and we cannot guarantee the security
          of your personal information.
        </p>
        <p className="text-lg mb-5 font-normal text-gray-800">Changes to This Policy</p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
          We will notify you of any material changes by posting the updated policy on our website.
        </p>
        <p className="text-lg mb-5 font-normal text-gray-800">
          By using PollsBoard.com, you consent to the terms of this Privacy Policy.
        </p>
        <div id="get_height"></div>
      </Container>
    </section>
    </>
  );
};
