import React, { useState, useRef, useEffect, useLayoutEffect, forwardRef, MutableRefObject } from "react";
import Quill from "quill";
import Delta from "quill-delta";
import "quill/dist/quill.snow.css";

type Range = any;
type TextChangeHandler = (delta: Delta, oldDelta: Delta, source: string) => void;
type SelectionChangeHandler = (range: Range | null, oldRange: Range | null, source: string) => void;

interface EditorProps {
  readOnly: boolean;
  defaultValue: any;
  onTextChange: TextChangeHandler;
  onSelectionChange: SelectionChangeHandler;
  onKeyDown: (event: KeyboardEvent) => void;
  onBlur: () => void;
}

const Editor = forwardRef<Quill, EditorProps>(
  (
    { readOnly, defaultValue, onTextChange, onSelectionChange, onKeyDown, onBlur },
    ref: MutableRefObject<Quill | null>
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    }, [onTextChange, onSelectionChange]);

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
      const quill = new Quill(editorContainer, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ indent: "-1" }, { indent: "+1" }],
          ],
        },
      });

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      if (ref && typeof ref === "object") {
        ref.current = quill;
      }

      quill.on("text-change", (delta: Delta, oldDelta: Delta, source: string) => {
        onTextChangeRef.current(delta, oldDelta, source);
      });

      quill.on("selection-change", (range: Range | null, oldRange: Range | null, source: string) => {
        onSelectionChangeRef.current(range, oldRange, source);
      });

      const handleKeyDown = (event: KeyboardEvent) => {
        onKeyDown(event);
      };
      quill.root.addEventListener("keydown", handleKeyDown);

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, []);
    return <div ref={containerRef} />;
  }
);

Editor.displayName = "Editor";

interface DescriptionBoxProps {
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  onBlur: () => void;
}

export const DescriptionBox = ({ description, setDescription, onBlur }: DescriptionBoxProps) => {
  const quillRef = useRef(null);
  const [characterLength, setCharacterLength] = useState(0);
  const onEditorStateChange = () => {
    if (quillRef.current) {
      const value = quillRef.current.root.innerHTML;
      setDescription(value);
      const cleanValue = value.replace(/<(.|\n)*?>/g, "").trim();
      if (cleanValue === "" || value === "<p><br></p>") {
        setDescription(null);
      }
      setCharacterLength(quillRef.current.getLength());
    }
  };
  const checkCharacterCount = (event: KeyboardEvent) => {
    if (quillRef.current && quillRef.current.getLength() > 3000 && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold mb-2">
        Description
      </div>
      <Editor
        ref={quillRef}
        readOnly={false}
        defaultValue={description ? new Delta().insert(description) : new Delta()}
        onTextChange={onEditorStateChange}
        onKeyDown={checkCharacterCount}
        onSelectionChange={() => {}}
        onBlur={onBlur}
      />
      <div className="text-end text-gray-500">{characterLength}/3000</div>
    </div>
  );
};
