import { useState } from "react";

const CommentForm = ({ handleSubmit, submitLabel, hasCancelButton = false, handleCancel, initialText = "" }) => {
  const [text, setText] = useState(initialText);
  const isTextareaDisabled = text.length === 0;
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };
  return (
    <form onSubmit={onSubmit} style={{ marginBottom: "20px" }}>
      <input
        type="text"
        aria-label="Your comment"
        style={{ width: "100%", padding: "10px", marginBottom: "10px", border: "1px solid #ccc", borderRadius: "4px" }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="What do you think?"
      />
      <button
        className={`${
          submitLabel === "Update" || submitLabel === "Reply" ? "py-1 px-3" : "py-1.5 px-4"
        } bg-headerBlue hover:bg-[#35405f] border-headerBlue-dark text-white font-medium border rounded transition duration-300 ease-in-out focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed`}
        disabled={isTextareaDisabled}
        aria-label={`Submit ${submitLabel.toLowerCase()} comment`}
      >
        {submitLabel}
      </button>
      {hasCancelButton && (
        <button
          type="button"
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-1 px-3 border border-gray-400 rounded transition duration-300 ease-in-out focus:outline-none focus:shadow-outline ml-2"
          onClick={handleCancel}
          aria-label="Cancel comment"
        >
          Cancel
        </button>
      )}
    </form>
  );
};

export default CommentForm;
