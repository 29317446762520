import { NavLink, useLocation } from "react-router-dom";
//import { useAppDispatch } from "../hooks";
// import { onUserLogout } from "../store/slices/auth.slice";
import { sideBar } from "./sideMenu";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";


export const Sidebar = () => {
  const location = useLocation();
  //const dispatch = useAppDispatch();

  // const handleLogout = () => {
  //   dispatch(onUserLogout({}));
  // };
  const isAdmin = useAppSelector((state: RootState) => state?.auth?.user?.role);

  return (
    <div className="py-2 px-2 md:px-5 md:py-4">
      {/* <div className="text-center mb-5 md:mb-10">
        <img src="/img/logo512.png" className="mx-auto" width="40" />
      </div> */}
      <ul>
        {sideBar.map((menu) => (
          <li
            key={menu.path}
            className={`text-center mb-5 ${
              location.pathname.includes(menu.path) ? "text-blue-600 border-blue-600" : "text-gray-600 border-white"
            } hover:text-blue-600 font-bold border-l-4 hover:border-blue-600`}
          >
            <NavLink className={({ isActive }) => `${isActive ? 'active' : 'inactive'} flex`} to={menu.path}>
              <span className="mr-1 w-[30px]">{menu.icon}</span>
              {menu.title}
            </NavLink>
          </li>
        ))}
        {
          isAdmin === "admin" && (
            <li className="text-center mb-5 hover:text-blue-600 text-gray-600 border-white font-bold border-l-4 hover:border-blue-600">
              <NavLink to="/admin" className={({ isActive }) => `${isActive ? 'active' : 'inactive'} flex`}>
                <span className="mr-1 w-[30px]"><i className="fas fa-user-shield"></i></span>
                Admin
              </NavLink>
            </li>
          )
        }
      </ul>
    </div>
  );
};
