import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { GOOGLE_CLIENT_ID } from "../constant";
import { useMutation } from "@apollo/react-hooks";
import { API_KEYS } from "../graphql/models";

interface AuthLayoutType {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutType) => {
  const [GoogleTokenMutation] = useMutation(API_KEYS);
  const [GoogleClientId, setGoogleCLientId] = useState("");

  const handleKey = async () => {
    try {
      const { data } = await GoogleTokenMutation();
      const key = data?.GetTokens?.data[4];
      const decryptKey = atob(key);
      setGoogleCLientId(decryptKey);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleKey();
  }, []);

  return (
    GoogleClientId && (
      <div>
        {/* <AuthHeader /> */}
        <GoogleOAuthProvider clientId={GoogleClientId}>
          {children}
        </GoogleOAuthProvider>
      </div>
    )

    // <div>
    //   {/* <AuthHeader /> */}
    //   <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>{children}</GoogleOAuthProvider>
    // </div>
  );
};
