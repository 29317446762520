import React from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "../container/Footer";
import { Header } from "../container/Header";
import { NotFound } from "../pages/NotFound";
import { AuthRoutes } from "./AuthRoutes";
import { PrivateRoutes } from "./PrivateRoutes";
import { Admin, Auth, Private, Public } from "./Router";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const AppRouter: React.FC = () => {
  const isAdmin = useAppSelector((state: RootState) => state?.auth?.role);

  return (
    <div className="flex flex-col">
      <div className="flex-col justify-start">
        <Header />
      </div>
      <div className="min-h-0 md:min-h-screen">
        <Routes>
          {Public.map((R) => (
            <Route key={R.path} path={R.path} element={<R.element />} />
          ))}
          <Route element={<PrivateRoutes />}>
            {Private.map((R) => (
              <Route key={R.path} path={R.path} element={<R.element />} />
            ))}
            {isAdmin === "admin" &&
              Admin.map((R) => (
                <Route key={R.path} path={R.path} element={<R.element />} />
              ))}
          </Route>
          <Route element={<AuthRoutes />}>
            {Auth.map((R) => (
              <Route key={R.path} path={R.path} element={<R.element />} />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <div className="flex-col justify-end">
        <Footer />
      </div>
    </div>
  );
};

export default AppRouter;
