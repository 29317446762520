import gql from "graphql-tag";

export const FETCH_USER_NOTIFICATIONS = gql`
  query fetchNotifications($userId: ID!) {
    fetchNotifications(userId: $userId) {
    code
    count
    notifications {
      _id
      title
      message
      userId
      notify_time
      isNewNotification
      }
    }
  }
`;

export const FETCH_USER_NOTIFICATIONS_NEW = gql`
  query fetchNewNotifications($userId: ID!) {
    fetchNewNotifications(userId: $userId) {
    code
    count
    notifications {
      _id
      title
      message
      userId
      notify_time
      isNewNotification
      }
    }
  }
`;

export const ADD_NOTIFICATION = gql`
mutation addNotification($userId: ID!, $title: String!, $message: String!, $notify_time: String, $isNewNotification: Boolean!) {
  addNotification(
    notificationInput:{
      userId: $userId,
      title: $title,
      message: $message,
      notify_time: $notify_time,
      isNewNotification: $isNewNotification
    }
  ) {
    code
    success
    message
    _id
    token
  }
}
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($notifyId: ID!, $isNewNotification: Boolean!) {
    updateNotification(
      notificationUpdateInput:{
        notifyId: $notifyId,
        isNewNotification: $isNewNotification
      }
    ) {
      code
      success
      message
      _id
      token
      }
    }
  `;

export const GET_NOTIFICATION_MESSAGE = gql`
  query getNotificationMessage($key: String!) {
    getNotificationMessage(key: $key) {
      title
      message
    }
  }
`;

