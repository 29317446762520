import { BoltIcon, ChartBarIcon, HeartIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import QRCode from "qrcode.react";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FETCH_VERIFICATION_BY_TOKEN, FETCH_USER_BY_ID } from "../../graphql/models";
import { client } from "../../graphql/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onUserLogin } from "../../store/slices/auth.slice";
import { warnNotify } from "../../util";

interface ClearVerificationProps {
  endpoint: string;
  userToken: string;
}

export function ClearVerification(props: ClearVerificationProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fetchError, setFetchError] = useState(null);
  const { data: userdata, error } = useQuery(FETCH_VERIFICATION_BY_TOKEN, {
    variables: { token: props.userToken },
    onError: (err) => {
      setFetchError(err);
      warnNotify("Failed to fetch verification data.");
    },
  });
  const isClearVerified = userdata?.FetchUserByToken?.user?.isClearVerified;
  const newToken = userdata?.FetchUserByToken?.token;
  const user = userdata?.FetchUserByToken?.user;

  // const { data, loading, error2 } = useQuery(FETCH_USER_BY_ID, {
  //   variables: { id: userId },
  //   skip: !isClearVerified,
  // });

  useEffect(() => {
    if (error) {
      setFetchError(error);
      warnNotify("An error occurred while fetching verification data.");
    }
  }, [error]);

  useEffect(() => {
    if (isClearVerified) return;

    const interval = setInterval(() => {
      client.refetchQueries({ include: [FETCH_VERIFICATION_BY_TOKEN] }).catch((err) => {
        setFetchError(err);
        warnNotify("Error occurred while refetching verification data.");
      });
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 1000 * 60 * 5);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isClearVerified]);

  //previously this would dispatch to the userlogin reducer with the old Token, the FE doesn't have an easy way to get the newToken created by CreateClearSession if the user starts on one device (laptop or desktop) and then verifies on another (mobile). the problem is that onUserLogin never gets a token also.
  useEffect(() => {
    if (isClearVerified) {
      // if (isClearVerified && !loading && data) {
      try {
        // dispatch(onUserLogin({ user: data?.FetchUserById.user}));
        navigate("/login");
      } catch (err) {
        setFetchError(err);
        console.error("Error during user login:", err);
        warnNotify("Error occurred while dispatching user login.");
      }
    }
  }, [isClearVerified, dispatch, navigate, error]);

  // const isMobile = () => {
  //   return (
  //     /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent) ||
  //     /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  //   );
  // };

  const isMobile = () => {
    const hasTouchScreen =
      "ontouchstart" in window || navigator.maxTouchPoints > 0 || window.navigator.maxTouchPoints > 0;
    if (hasTouchScreen) {
      const ua = navigator.userAgent;
      if (/iPad|iPhone|iPod|webOS|silk|playbook/.test(ua) || /Android/.test(ua)) {
        return true;
      }
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isLandscape = screenWidth > screenHeight;
      const isTabletScreenSize = screenWidth >= 768 && screenHeight >= 1024;
      if (screenWidth <= 1024) {
        // Typically tablet screen width under desktop viewport
        return true;
      } else if (isTabletScreenSize && isLandscape) {
        return true;
      }
    }
    return false;
  };

  const handleClick = () => {
    const popupWidth = 500;
    const popupHeight = 820;
    //
    // Calculating the position to center the popup
    const left = window.top.outerWidth / 2 + window.top.screenX - popupWidth / 2;
    const top = window.top.outerHeight / 2 + window.top.screenY - popupHeight / 2;

    // Opening the popup window in the center of the screen
    window.open(props.endpoint, "_blank", `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
  };

  //console.log("CLEAR Endpont", props.endpoint);
  //
  if (fetchError) {
    return (
      <div className="shadow-lg rounded-lg bg-white p-8 max-w-md mx-auto mt-5">
        <div className="mb-4 text-red-500">An error occurred: {fetchError.message}</div>
        <p>
          Verification was unsuccessful or may have timed out. If you have attempted clear verification 3 times, then
          please wait 5 minutes before trying again. Otherwise, please refresh the page now and try again. If you
          require assistance, please reach out to Support@pollsboard.com.
        </p>
      </div>
    );
  } else {
    return (
      <div className="shadow-lg rounded-lg bg-white p-8 max-w-md mx-auto mt-5">
        <div className="flex flex-col md:flex-row items-center justify-center mb-4">
          <img src="/img/logo_cutout.png" alt="PollsBoard Logo" className="h-10" />
          <span className="font-serif text-[#041E53] font-bold text-2xl ml-1">PollsBoard</span>
          <div className="border-r border-gray-500 h-10 mx-4 rotate-90 md:rotate-0"></div>
          <img src="/img/CLEAR_Logo.png" alt="Clear Logo" className="h-10" />
        </div>
        <h6 className="font-semibold text-xl text-center text-gray-700 mb-5">
          To ensure the integrity of our polls we require users to be verified
        </h6>
        <div className="ml-5">
          <p className="flex items-center justify-left text-gray-500 mb-4">
            <ShieldCheckIcon className="w-5 h-5 text-blue-500 mr-2" /> Your data is secure with CLEAR
          </p>
          <p className="flex items-center justify-left text-gray-500 mb-4">
            <ChartBarIcon className="w-5 h-5 text-blue-500 mr-2" /> We will handle the polling
          </p>
          <p className="flex items-center justify-left text-gray-500 mb-4">
            <BoltIcon className="w-5 h-5 text-blue-500 mr-2" /> Just scan your driver's license and take a selfie
          </p>
          <p className="flex items-center justify-left text-gray-500 mb-6">
            <HeartIcon className="w-5 h-5 text-blue-500 mr-2" /> Trusted by more than 22 million people
          </p>
        </div>
        {isMobile() ? (
          <button
            onClick={handleClick}
            className="w-full bg-[#041E53] py-3 rounded-full text-white font-semibold hover:bg-[#1B3A70] hover:shadow-lg flex items-center justify-center transition-colors"
          >
            <img src="/img/CLEAR_Logo_Horizontal_White.png" alt="CLEAR Logo" className="inline h-6 mr-2" />
            Verify with CLEAR
          </button>
        ) : (
          // If on desktop, display the QR code
          <div className="justify-center">
            <p className="text-center mt-2">Scan to verify with CLEAR</p>
            <div className="flex items-center justify-center">
              <QRCode
                value={props.endpoint}
                size={256} // QR code size
                bgColor={"#ffffff"} // Background color
                fgColor={"#000000"} // Foreground color
                level={"Q"} // Error correction level
                includeMargin={true} // Adds white margin around QR code
                renderAs={"svg"} // Render method
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
