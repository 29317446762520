import { notification } from "antd";

// interface notifyTypes {
//   data: any;
//   refetch?: Function;
//   clearState?: Function;
// }

notification.config({
  top: 80,
  placement: "topLeft",
  duration: 5,
});

export const successNotify = (message) => {
  notification.success({
    message,
  });
};

export const warnNotify = (message) => {
  notification.warning({
    message,
  });
};

export const notify = (data: any) => {
  console.log(data);
  if (data.success) {
    notification.success({
      message: data.message,
    });
  } else {
    notification.warning({
      message: data.message,
    });
  }
};
