import { useQuery } from "@apollo/client";
import { Pagination, Spin } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, PollCard } from "../components";
import { FETCH_POLLS } from "../graphql/models";
import { setPage, setSearchCategory, setSearchText, setVotesFilters, setPollsFilters} from "../store/slices/auth.slice";

export const Home = () => {
  const [limit] = useState(9);
  const dispatch = useDispatch();
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchTag, searchText, searchCategory, votesFilters, pollsFilters, page } = useSelector(
    (state: any) => state?.auth
  );

  const { data, loading, refetch } = useQuery(FETCH_POLLS, {
    variables: {
      limit,
      offset: (page - 1) * limit,
      searchText,
      searchTag,
      searchCategory,
      votesFilters: votesFilters,
      pollsFilters: pollsFilters,
    },
    fetchPolicy: "network-only",
  });
  const polls = data?.FetchPoll?.result || [];
  const total = data?.FetchPoll?.count || 0;

  // useEffect(() => {
  //   console.log(`Refetching data for page: ${page}`);
  //   refetch();
  // }, [page, refetch]);

  // useEffect(() => {
  //   console.log(`Component rendered. Page: ${page}, Filters:`, filters);
  // });

  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = searchParams.get("page");

    if (pageFromUrl && Number(pageFromUrl) !== page) {
      dispatch(setPage(Number(pageFromUrl)));
    } else if (!pageFromUrl) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("page", page.toString());
      navigate({ pathname: location.pathname, search: newSearchParams.toString() }, { replace: true });
    }
  }, [location.search, page, dispatch, navigate]);

  useEffect(() => {
    // When filters change, reset page to 1
    dispatch(setPage(1));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [
    searchText,
    searchTag,
    searchCategory,
    votesFilters,
    pollsFilters,
    dispatch,
    navigate,
    location.pathname,
  ]);

  const onChangePagination = (p) => {
    dispatch(setPage(p));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', p.toString());
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };



  // useEffect(() => {
  //   refetch({
  //     limit,
  //     offset: (page - 1) * limit,
  //     searchText,
  //     searchTag,
  //     searchCategory,
  //     filter: filters,
  //   });
  // }, [page]);

  useEffect(() => {
    dispatch(setSearchCategory({ searchCategory: category }));
  }, [category]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <section id="card" className="bg-slate-200 py-10">
          <Container>
            <Spin spinning={loading}>
              <div id="get_height">
                {polls.length === 0 && !loading && (
                  <p className="w-full pt-5 text-center font-bold text-black">There is no poll</p>
                )}
                <div className="grid grid-cols-12 gap-y-12 gap-x-0 sm:gap-x-12">
                  {polls?.map((poll: any) => (
                    <PollCard key={poll?._id} data={poll} totalVote={poll.totalVote} refetch={refetch} />
                  ))}
                </div>
              </div>
            </Spin>
          </Container>
        </section>
      </div>
      <div className="mb-7">
        <Pagination
          className="flex justify-center"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
};
