import { useMutation } from "@apollo/react-hooks";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APPLE_LOGIN, GOOGLE_LOGIN, USER_LOGIN, FACEBOOK_LOGIN, API_KEYS } from "../graphql/models";
import { useAppDispatch } from "../hooks";
import { onUserLogin } from "../store/slices/auth.slice";
import { successNotify, warnNotify } from "../util";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
// import { FACEBOOK_APP_ID } from "../constant";
import { statusCode } from "../config";
import { AppleFilled } from "@ant-design/icons";

export const Signin = () => {
  const [email, setEmail] = useState("");
  const [facebookAppId, setFacebookAppId] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const disable = !email || !password;

  const [GoogleSignInMutation] = useMutation(GOOGLE_LOGIN);
  const handleGoogleSignin = async (idToken: String) => {
    try {
      const {
        data: { GoogleSignIn },
      } = await GoogleSignInMutation({
        variables: {
          idToken,
        },
      });
      if (GoogleSignIn.success) {
        dispatch(onUserLogin(GoogleSignIn));
        successNotify(GoogleSignIn.message);
      } else {
        if (GoogleSignIn.code === statusCode.ACCESS_DENIED) {
          navigate(`/register?code=${GoogleSignIn.token}`);
        } else {
          warnNotify(GoogleSignIn.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [FacebookTokenMutation] = useMutation(API_KEYS);

  const handleKey = async () => {
    try {
      const { data } = await FacebookTokenMutation();
      const key = data?.GetTokens?.data[5];
      const decryptKey = atob(key);
      setFacebookAppId(decryptKey);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleKey();
  }, []);

  const handleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleGoogleSignin(tokenResponse.access_token);
    },
    onError: () => {
      warnNotify("Something went wrong!");
    },
    // flow: "auth-code",
  });

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      handleGoogleSignin(credentialResponse.credential);
      console.log(credentialResponse);
    },
    onError: () => {
      warnNotify("Something went wrong!");
    },
  });

  const [LoginMutation, { loading }] = useMutation(USER_LOGIN);
  const onSubmit = async () => {
    try {
      const {
        data: { Login },
      } = await LoginMutation({
        variables: {
          email,
          password,
        },
      });
      if (Login.success) {
        dispatch(onUserLogin(Login));
        successNotify(Login.message);
      } else {
        if (Login.code === statusCode.ACCESS_DENIED) {
          navigate(`/register?code=${Login.token}`);
        } else {
          warnNotify(Login.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [FacebookSignInMutation] = useMutation(FACEBOOK_LOGIN);
  const handleFacebookSignIn = async (response: any) => {
    try {
      const {
        data: { FacebookSignIn },
      } = await FacebookSignInMutation({
        variables: {
          token: response?.accessToken,
        },
      });

      if (FacebookSignIn.success) {
        dispatch(onUserLogin(FacebookSignIn));
        successNotify(FacebookSignIn.message);
      } else {
        if (FacebookSignIn.code === statusCode.ACCESS_DENIED) {
          navigate(`/register?code=${FacebookSignIn.token}`);
        } else {
          warnNotify(FacebookSignIn.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [AppleSignInMutation] = useMutation(APPLE_LOGIN);

  const handleAppleSignIn = async (response: any) => {
    try {
      const {
        data: { SignInWithApple },
      } = await AppleSignInMutation({
        variables: {
          idToken: response.authorization.id_token,
          firstName: response?.user?.name?.firstName,
          lastName: response?.user?.name?.lastName,
        },
      });

      if (SignInWithApple.success) {
        dispatch(onUserLogin(SignInWithApple));
        successNotify(SignInWithApple.message);
      } else {
        if (SignInWithApple.code === statusCode.ACCESS_DENIED) {
          navigate(`/register?code=${SignInWithApple.token}`);
        } else {
          warnNotify(SignInWithApple.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleForgotEmail = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_FORGOT_EMAIL_URL);
      const data = await response.json();
      const token = data.token;

      navigate(`/forgot-email?code=${encodeURIComponent(token)}`);
    } catch (error) {
      console.error("Failed to initiate forgot email process:", error);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="py-12 grid place-items-center">
        <div className="px-6 md:w-[550px] sm:w-ful w-full">
          <div className="bg-white rounded-lg shadow-xl hover:2xl p-6">
            <h2 className="text-center text-gray-700 font-bold text-4xl pb-8">Login</h2>
            <div className="mb-5">
              <div className="text-base text-gray-700 font-semibold mb-2">Email</div>
              <input
                className="border focus:border w-full rounded-lg px-4 py-2"
                type="text"
                placeholder="Email address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-10">
              <div className="text-base text-gray-700 font-semibold mb-2">Password</div>
              <input
                className="border focus:border w-full rounded-lg px-4 py-2"
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="w-full py-2 rounded-lg font-semibold bg-blue-500 hover:bg-blue-700  text-white text-xl cursor-pointer"
              disabled={disable}
              onClick={onSubmit}
            >
              {loading ? "Logging in..." : "Log in"}
            </button>

            <div className="flex justify-between">
              <div className="mt-5">
                Don't have account?{" "}
                <Link to="/register" className="text-sky-500 hover:text-sky-600 hover:underline">
                  Register
                </Link>
              </div>
              <div className="flex flex-col">
                {/* <div className="mt-5">
                  <button
                    className="text-sky-500 hover:text-sky-600 hover:underline"
                    onClick={initiateForgotEmailProcess}
                  >
                    Forgot email
                  </button>
                </div> */}
                <div className="mt-5">
                  <Link to="/forget-password" className="text-sky-500 hover:text-sky-600 hover:underline">
                    Forgot password
                  </Link>
                </div>
              </div>
            </div>
            <div className="mb-6 mt-5 text-center font-semibold flex items-center">
              <span className="bg-gray-400 h-[1px] w-full" />
              <span className="mx-5">Or</span>
              <span className="bg-gray-400 h-[1px] w-full" />
            </div>
            <div className="w-full">
              <button
                onClick={() => handleLogin()}
                className="w-full flex justify-center items-center border-[2px] py-2 rounded-lg bg-slate-100 hover:bg-slate-200 text-lg"
              >
                <img src="/img/auth/google.png" width="19" className="mr-2" alt="" /> Continue with Google
              </button>
            </div>

            <div className="w-full">
              {facebookAppId ? (
                <FacebookLogin
                  appId={facebookAppId}
                  fields="name,email,picture"
                  callback={handleFacebookSignIn}
                  render={(renderProps: any) => (
                    <button
                      onClick={renderProps.onClick}
                      className="w-full mt-2 flex justify-center items-center border-[2px] py-2 rounded-lg bg-blue-500 hover:bg-blue-700  text-white text-lg"
                    >
                      <img alt="" src="/img/auth/facebook.png" width="19" className="mr-2" />
                      Continue with Facebook
                    </button>
                  )}
                />
              ) : (
                <button
                  onClick={() => alert("Facebook Login Error!")}
                  className="w-full mt-2 flex justify-center items-center border-[2px] py-2 rounded-lg bg-blue-500 hover:bg-blue-700  text-white text-lg"
                >
                  <img alt="" src="/img/auth/facebook.png" width="19" className="mr-2" />
                  Continue with Facebook
                </button>
              )}
            </div>

            <div className="w-full">
              <AppleLogin
                clientId="com.pollsboard"
                redirectURI="https://pollsboard.com/register"
                usePopup={true}
                callback={handleAppleSignIn} // Catch the response
                scope="email name"
                // responseType="code"
                responseMode="query"
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button
                    onClick={renderProps.onClick}
                    className="mt-2 w-full flex justify-center items-center border-[2px] py-2 text-white rounded-lg bg-black hover:bg-slate-700 text-black text-lg"
                  >
                    <AppleFilled />
                    <img src="/img/auth/apple.png" width="1" className="mr-2" alt="" />
                    Continue with Apple
                  </button>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
