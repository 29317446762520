function getHost() {
  if (globalThis.location.hostname === "dev.pollsboard.com") {
    return "pollsboard-server-dev-6wdtq.ondigitalocean.app";
  }
  if (globalThis.location.hostname === "localhost") return "localhost:5000";

  return "pollsboard-server-prod-9llto.ondigitalocean.app";
}

const httpProtocol = globalThis.location.protocol;
const wsProtocol = globalThis.location.protocol === "https:" ? "wss:" : "ws:";

export const GRAPHQL_URL = `${httpProtocol}//${getHost()}/graphql`;
export const GRAPHQL_URL_WS = `${wsProtocol}//${getHost()}/graphql`;
export const CLEAR_CALLBACK_SERVER = `${httpProtocol}//${getHost()}/clear-callback`;
// export const CLEAR_CALLBACK_URL = "https://pollsboard-server-dev-6wdtq.ondigitalocean.app/clear-callback";
