import {  BasicInfo, OtherAccountInfo, PrivateContainer } from "../components";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { FETCH_USER_BY_ID_OAUTH, } from "../graphql/models";

export const Settings = () => {

  const user = useSelector((state: any) => state?.auth?.user);

  const { data, loading } = useQuery(FETCH_USER_BY_ID_OAUTH, {
    variables: { id: user?._id },
  });
  return (
    <PrivateContainer pageTitle="Settings">
      <BasicInfo data={data} loading={loading}/>
      <OtherAccountInfo data={{ data }}/>
    </PrivateContainer>
  );
};
