import gql from "graphql-tag";

export const CREATE_POLL = gql`
  mutation CreatePoll($pollInput: PollInput, $tags: [String], $options: [String]) {
    CreatePoll(pollInput: $pollInput, tags: $tags, options: $options) {
      code
      success
      message
    }
  }
`;

export const ADD_VOTE = gql`
  mutation AddVote($pollId: ID, $optionId: ID) {
    AddVote(pollId: $pollId, optionId: $optionId) {
      code
      success
      message
    }
  }
`;

export const REMOVE_POLL = gql`
  mutation RemovePoll($pollId: ID) {
    RemovePoll(pollId: $pollId) {
      code
      success
      message
    }
  }
`;
export const ADD_OPTION = gql`
  mutation AddOption($pollId: ID, $text: String, $isOwner: Boolean) {
    AddOption(pollId: $pollId, text: $text, isOwner: $isOwner) {
      code
      success
      message
    }
  }
`;

export const REMOVE_OPTION = gql`
  mutation RemoveOption($pollId: ID, $optionId: ID) {
    RemoveOption(pollId: $pollId, optionId: $optionId) {
      code
      success
      message
    }
  }
`;

export const UPDATE_POLL_OPTION = gql`
  mutation UpdatePolloptions($pollId: ID, $optionId: ID, $accepted: Boolean) {
    UpdatePolloptions(pollId: $pollId, optionId: $optionId, accepted: $accepted) {
      code
      success
      message
    }
  }
`;

export const FETCH_POLLS = gql`
  query FetchPoll(
    $limit: Int
    $offset: Int
    $userId: ID
    $email: String
    $followed: Boolean
    $voted: Boolean
    $searchText: String
    $searchTag: [String]
    $searchCategory: String
    $votesFilters: FilterInput
    $pollsFilters: FilterInput
  ) {
    FetchPoll(
      limit: $limit
      offset: $offset
      userId: $userId
      email: $email
      followed: $followed
      voted: $voted
      searchText: $searchText
      searchTag: $searchTag
      searchCategory: $searchCategory
      votesFilters: $votesFilters
      pollsFilters: $pollsFilters
    ) {
      code
      count
      result {
        _id
        question
        description
        totalVote
        suggestionable
        category
        options {
          _id
          text
          accepted
          voteCount
          votes {
            _id
            user {
              _id
            }
          }
        }
        tags {
          _id
          text
          pollCount
        }
        user {
          _id
          email
        }
        locationRestriction {
          city
          locationState
        }
        genderRestriction {
          gender
        }
        ageRangeRestriction {
          minAge
          maxAge
        }
        followers {
          _id
        }
        createdAt
      }
    }
  }
`;
export const FETCH_QUESTIONS = gql`
  query FetchPoll {
    FetchPoll {
      count
      result {
        question
      }
    }
  }
`;

export const ADD_OR_REMOVE_POLL = gql`
  mutation AddOrRemoveFollower($pollId: ID) {
    AddOrRemoveFollower(pollId: $pollId) {
      code
      success
      message
    }
  }
`;

export const FETCH_SINGLE_POLL = gql`
  query FetchSinglePoll($id: ID) {
    FetchSinglePoll(id: $id) {
      code
      success
      message
      poll {
        _id
        question
        description
        suggestionable
        totalVote
        tags {
          _id
          text
          pollCount
        }
        options {
          _id
          text
          accepted
          owner {
            _id
            firstname
            lastname
            email
          }
          voteCount
          votes {
            _id
            user {
              _id
            }
          }
        }
        followers {
          _id
          firstname
          lastname
        }
        user {
          _id
          email
        }
      }
    }
  }
`;
export const FETCH_SINGLE_POLL_BY_TEXT = gql`
  query FetchSinglePollByText($questionId: String, $votesFilters: FilterInput) {
    FetchSinglePollByText(questionId: $questionId, votesFilters: $votesFilters) {
      code
      success
      message
      poll {
        _id
        question
        description
        suggestionable
        category
        totalVote
        tags {
          _id
          text
          pollCount
        }
        locationRestriction {
          city
          locationState
        }
        genderRestriction {
          gender
        }
        ageRangeRestriction {
          minAge
          maxAge
        }
        options {
          _id
          text
          accepted
          owner {
            _id
            firstname
            lastname
            email
          }
          voteCount
          votes {
            _id
            user {
              _id
            }
          }
        }
        followers {
          _id
          firstname
          lastname
        }
        user {
          _id
          email
        }
      }
    }
  }
`;
