export default {
    BAD_REQUEST: "BAD_REQUEST",
    BLOCKED: "BLOCKED",
    VALIDATION_ERROR: "VALIDATION_ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",
    INTERNAL_ERROR: "INTERNAL_ERROR",
    PERMISSION_DENIED: "PERMISSION_DENIED",
    ACCESS_DENIED: "ACCESS_DENIED",
    NOT_FOUND: "NOT_FOUND",
    CREATED: "CREATED",
    ADDED: "ADDED",
    UPDATED: "UPDATED",
    DELETED: "DELETED",
    OK: "OK",
    LIMIT_EXCEED: "LIMIT_EXCEED",
    STOCK_OUT: "STOCK_OUT",
  };
