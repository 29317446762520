import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { GRAPHQL_URL, GRAPHQL_URL_WS } from "../config";

const getToken = () => {
  try {
    const token = JSON.parse(JSON.parse(localStorage.getItem("persist:auth"))?.token);
    return token;
  } catch (error) {
    return null;
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (Array.isArray(graphQLErrors) && graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
    //   window.localStorage.clear();
    //   window.localStorage.setItem("customCoockie", "ok");
    //   window.location.reload();
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const uploadLink = createUploadLink({
  uri: GRAPHQL_URL,
  headers: {
    Authorization: getToken(),
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: getToken(),
    },
  };
});

const wsLink = new WebSocketLink({
  uri: GRAPHQL_URL_WS,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: getToken(),
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  authLink.concat(uploadLink)
);

export const client = new ApolloClient({
  link: ApolloLink.from([splitLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // defaultHttpLink: false,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});
