import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_NOTIFICATION, GET_NOTIFICATION_MESSAGE } from "../graphql/models";

export const AdminPage = () => {
  const [addNotification] = useMutation(ADD_NOTIFICATION);
  const [notificationType, setNotificationType] = useState("all");
  const [userId, setUserId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  // const { data, loading, error } = useQuery(GET_NOTIFICATION_MESSAGE, {
  //   variables: { key: 'welcome' }
  // });

  // console.log(data);

  const handleSendNotification = async () => {
    // const { welcometitle, welcomemessage } = data.getNotificationMessage;
    // console.log(welcometitle,welcomemessage);

    if (notificationType === "specific" && !userId) {
      alert("Please enter a user ID");
      return;
    }

    try {
      await addNotification({
        variables: {
          userId,
          title,
          message,
          notify_time: new Date().toISOString(),
          isNewNotification: true, // Adjust based on your schema
        },
      });
      alert("Notification sent successfully.");
    } catch (error) {
      console.error("Error sending notification:", error);
    }

    console.log({
      type: notificationType,
      userId: notificationType === "specific" ? userId : undefined,
      title,
      message,
    });

    // Reset form fields after sending
    setUserId("");
    setTitle("");
    setMessage("");
  };

  return (
    <div className="max-w-8xl mt-5 mx-2 p-6 bg-white shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Notify Users</h2>
      <div className="mb-4">
        <label htmlFor="notificationType" className="block text-sm font-medium text-gray-700">
          Notification Type
        </label>
        <select
          id="notificationType"
          value={notificationType}
          onChange={(e) => setNotificationType(e.target.value)}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="all">All Users</option>
          <option value="specific">Specific User</option>
        </select>
      </div>

      {notificationType === "specific" && (
        <div className="mb-4">
          <label htmlFor="userId" className="block text-sm font-medium text-gray-700">
            User ID
          </label>
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Enter User ID"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      )}

      <div className="mb-4">
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Notification Title"
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Notification Message"
          rows={4}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></textarea>
      </div>

      <button
        onClick={handleSendNotification}
        className="py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75"
      >
        Send Notification
      </button>
    </div>
  );
};
