import CommentForm from "./CommentForm";
import { ReactComponent as ThumbsUpOutlined } from "../../thumbs-up-Outlined.svg";
import { ReactComponent as ThumbsUpFilled } from "../../thumbsUpFilled.svg";
import { FETCH_USER_BY_ID } from "../../graphql/models";
import { useQuery } from "@apollo/react-hooks";

const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  likeComment,
  parentId = null,
  currentUserId,
  currentUserName,
}) => {
  const isEditing = activeComment && activeComment._id === comment._id && activeComment.type === "editing";
  const isReplying = activeComment && activeComment._id === comment._id && activeComment.type === "replying";
  const fiveMinutes = 300000;
  //timePassed is not used currently.
  const timePassed = new Date() - new Date(parseInt(comment.updatedAt)) > fiveMinutes;
  const canDelete = currentUserId === comment.userId && replies.length === 0;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.userId;
  const replyId = parentId ? parentId : comment._id;
  const createdAt = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(new Date(parseInt(comment.updatedAt)));
  let index = comment.likes.indexOf(currentUserId);

  const { data: userData } = useQuery(FETCH_USER_BY_ID, {
    variables: { id: comment.userId },
  });

  let commentUserName = userData?.FetchUserById?.user?.username;

  return (
    <article
      style={{ marginLeft: "1%", marginRight: "1%", width: "100%" }}
      aria-label={`Comment by ${commentUserName || "Anonymous"}`}
    >
      <div
        key={comment._id}
        style={{
          display: "flex",
          width: "100%",
          marginTop: parentId == null ? 5 : 0,
          marginLeft: parentId == null ? 0 : "2%",
        }}
      >
        <div>
          <header style={{ display: "flex", flexDirection: "row", alignItem: "center" }}>
            <strong style={{ marginRight: 3, color: "Black", fontSize: "14px" }}>
              {commentUserName || "Anonymous"}
            </strong>
            <time style={{ marginTop: 2, color: "#374151", fontSize: "13px" }}>{createdAt}</time>
          </header>
          {!isEditing && (
            <div style={{ marginLeft: 5, marginRight: 1, fontSize: "14px", color: "#070707", display: "flex-end" }}>
              {comment.body}
            </div>
          )}
          {isEditing && (
            <CommentForm
              submitLabel="Update"
              hasCancelButton
              initialText={comment.body}
              handleSubmit={(text) => updateComment(text, comment._id)}
              handleCancel={() => {
                setActiveComment(null);
              }}
            />
          )}
          <footer style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
            {canReply && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 2,
                  height: 25,
                  width: 35,
                  marginBottom: parentId == null ? 5 : 0,
                  color: "rgba(0, 0, 0, 0.55)",
                  fontSize: 12,
                }}
                className="cursor-pointer"
                onClick={() => {
                  setActiveComment({ _id: comment._id, type: "replying" });
                }}
                aria-label="Reply to comment"
              >
                Reply
              </button>
            )}
            {canEdit && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 2,
                  height: 25,
                  width: 35,
                  marginBottom: parentId == null ? 5 : 0,
                  color: "rgba(0, 0, 0, 0.55)",
                  fontSize: 12,
                }}
                className="cursor-pointer"
                onClick={() => setActiveComment({ _id: comment._id, type: "editing" })}
                aria-label="Edit comment"
              >
                Edit
              </button>
            )}
            {canDelete && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 5,
                  height: 25,
                  width: 35,
                  marginBottom: parentId == null ? 5 : 0,
                  color: "rgba(0, 0, 0, 0.55)",
                  fontSize: 12,
                }}
                className="cursor-pointer"
                onClick={() => deleteComment(comment._id)}
                aria-label="Delete comment"
              >
                Delete
              </button>
            )}
            <button
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 2,
                height: 25,
                width: 35,
                marginBottom: parentId == null ? 5 : 0,
                color: "rgba(0, 0, 0, 0.55)",
                fontSize: 12,
              }}
              className="cursor-pointer"
              onClick={() => likeComment(comment._id)}
              aria-label={`Like comment, current likes: ${comment.likes.length}`}
            >
              {index === -1 ? <ThumbsUpOutlined style={{ height: 15 }} /> : <ThumbsUpFilled style={{ height: 15 }} />}
              <span style={{ marginLeft: 5 }}>{comment.likes.length}</span>
            </button>
          </footer>
          {isReplying && <CommentForm submitLabel="Reply" handleSubmit={(text) => addComment(text, replyId)} />}
          {replies.length > 0 && (
            <section className="replies">
              {replies.map((reply) => (
                <Comment
                  comment={reply}
                  key={reply._id}
                  setActiveComment={setActiveComment}
                  activeComment={activeComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  addComment={addComment}
                  likeComment={likeComment}
                  parentId={comment._id}
                  replies={[]}
                  currentUserId={currentUserId}
                  currentUserName={currentUserName}
                />
              ))}
            </section>
          )}
        </div>
      </div>
      {parentId == null && <div style={{ height: "1px", width: "100%", backgroundColor: "rgba(0,0,0,0.2)" }}></div>}
    </article>
  );
};

export default Comment;
