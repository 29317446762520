import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { PrivateContainer } from "../components";
import { FETCH_USER_NOTIFICATIONS } from "../graphql/models";
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import utc from 'dayjs/plugin/utc';
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(utc);

interface Notification {
  _id: string;
  title: string;
  message: string;
  notify_time: string;  // Assuming notify_time is a Unix timestamp in milliseconds
  userId: string;
  isNewNotification: boolean;
}

export const AccountNotifications = () => {
  const { user } = useSelector((state: any) => state?.auth);
  const { data, loading } = useQuery(FETCH_USER_NOTIFICATIONS, {
    variables: { userId: user?._id },
    fetchPolicy: "cache-and-network",
  });

  const notifications = data?.fetchNotifications?.notifications || [];

  const formatDate = (timestamp: string) => {
    const timeconversion = new Date(parseInt(timestamp));
    const day = dayjs(timeconversion); // Convert from Unix timestamp
    if (day.isToday()) return 'Today';
    if (day.isYesterday()) return 'Yesterday';
    return day.format('MMM DD, YYYY');

  };
  return (
    <PrivateContainer pageTitle="Notifications" loading={loading}>
      <div className="p-6 overflow-y-auto h-200">
        {notifications.map((notification: Notification) => (
          <div key={notification._id} className="mb-4 pb-4 border-b last:border-b-0 last:pb-0 last:mb-0 flex justify-between items-start">
            <div>
              <h4 className="font-semibold mb-1">{notification.title}</h4>
              <p>{notification.message}</p>
            </div>
            <span className="text-gray-500 text-sm">{formatDate(notification.notify_time)}</span>
          </div>
        ))}
      </div>
    </PrivateContainer>
  );
};
