import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces";

interface AuthState {
  user?: User;
  isAuthenticated: boolean;
  token: string;
  role: string;
  reload: "";
  searchText: "";
  page: number;
  searchTag: [];
  searchCategory: string;
  votesFilters: {
    gender?: string;
    ageRange?: string;
    city?: string;
    locationState?: string;
    locationStateCode?: string;
  };
  pollsFilters: {
    gender?: string;
    minAge?: number;
    maxAge?: number;
    city?: string;
    locationState?: string;
  };
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  role: "",
  reload: "",
  page: 1,
  searchText: "",
  searchTag: [],
  searchCategory: "",
  votesFilters: {
    gender: undefined,
    locationState: undefined,
    ageRange: undefined,
    city: undefined,
  },
  pollsFilters: {
    gender: undefined,
    minAge: undefined,
    maxAge: undefined,
    city: undefined,
    locationState: undefined,
  },
} as AuthState;

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onUserLogin(state, action) {
      console.log(action.payload);
      const { user, token } = action.payload;
      const updatedUser = {
        ...user,
        appleEmail: user?.appleEmail || null,
        googleEmail: user?.googleEmail || null,
        facebookEmail: user?.facebookEmail || null,
        appleId: user?.appleId || null,
        googleId: user?.googleId || null,
        facebookId: user?.facebookId || null,
      }
      state.isAuthenticated = true;
      state.role = updatedUser.role;
      state.user = updatedUser;
      state.token = "Bearer " + token;
      setInterval(() => {
        window.location.reload();
      }, 500);
    },
    onUserLogout(state, action) {
      return {
        isAuthenticated: false,
        user: action.payload,
        token: "",
        role: action.payload,
        searchText: "",
        searchTag: [],
        searchCategory: "",
        reload: "",
        votesFilters: {},
        pollsFilters: {},
        page: 1,
      };
    },
    reloadMyPolls(state, action) {
      return { ...state, reload: action.payload.reload };
    },
    setSearchText(state, action) {
      return { ...state, searchText: action.payload.searchText || "", searchTag: action.payload.searchTag || [] };
    },
    setSearchCategory(state, action) {
      console.log(action.payload.searchCategory);
      return { ...state, searchCategory: action.payload.searchCategory };
    },
    setVotesFilters(state, action: PayloadAction<AuthState["votesFilters"]>) {
      return { ...state, votesFilters: { ...state.votesFilters, ...action.payload } };
    },
    resetVotesFilters(state) {
      state.votesFilters = initialState.votesFilters;
    },
    setPollsFilters(state, action: PayloadAction<AuthState["pollsFilters"]>) {
      return { ...state, pollsFilters: { ...state.pollsFilters, ...action.payload } };
    },
    resetPollsFilters(state) {
      state.pollsFilters = initialState.pollsFilters;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    }
  },
});

export const {
  onUserLogin,
  onUserLogout,
  setSearchText,
  reloadMyPolls,
  setSearchCategory,
  setVotesFilters,
  resetVotesFilters,
  setPollsFilters,
  resetPollsFilters,
  setPage,
} = slice.actions;
export default slice;
