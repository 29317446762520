import {
  AboutUs,
  AccountNotifications,
  AdminPage,
  Signup,
  CreatePolls,
  Disclaimer,
  FollowedPolls,
  ForgotEmail,
  ForgotPassword,
  Home,
  MyPolls,
  PollDetails,
  PrivacyPolicy,
  ResetPassword,
  Settings,
  Signin,
  TermsAndCondition,
  VotedPolls,
} from "../pages";
import { VerifyEmail } from "../pages/VerifyEmail";

export const Auth = [
  { path: "login", element: Signin },
  { path: "register", element: Signup },
  { path: "forgot-email", element: ForgotEmail },
  { path: "forget-password", element: ForgotPassword },
  { path: "reset-password", element: ResetPassword },
];

export const Public = [
  { path: "/", element: Home },
  { path: "/categories/:category", element: Home },
  { path: "/poll/:category/:code/*", element: PollDetails },
  { path: "/privacy-policy", element: PrivacyPolicy },
  { path: "/terms-and-conditions", element: TermsAndCondition },
  { path: "/disclaimer", element: Disclaimer },
  { path: "/about-us", element: AboutUs },
  { path: "/verify-email", element: VerifyEmail },
];

export const Private = [
  { path: "notifications", element: AccountNotifications },
  { path: "my-polls", element: MyPolls },
  { path: "voted-polls", element: VotedPolls },
  { path: "followed-polls", element: FollowedPolls },
  { path: "my-polls", element: MyPolls },
  { path: "create-poll", element: CreatePolls },
  { path: "settings", element: Settings },
];

export const Admin = [{ path: "admin", element: AdminPage }];
