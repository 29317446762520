import { useMutation, useQuery } from "@apollo/react-hooks";
import { Popconfirm, Spin, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADD_OR_REMOVE_POLL, ADD_VOTE, FETCH_SINGLE_POLL, REMOVE_POLL } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";
import { AddSuggession } from "../CreatePoll/AddSuggession";
import { PollOption } from "./PollOptions";

interface PollCardDrawerInter {
  data?: any;
  totalVote?: any;
  refetch?: Function;
}

export const PollCardDrawer = ({ data, totalVote, refetch }: PollCardDrawerInter) => {
  const navigate = useNavigate();

  const {data: pollSingle} = useQuery(FETCH_SINGLE_POLL);

  console.log(pollSingle);

  const user = useSelector((state: any) => state?.auth?.user);
  const { tags, options } = data || {};
  const isMine = user?._id === data?.user?._id;
  const isPollVoted = options.find(({ votes }) => votes.find((item) => item.user._id === user?._id))?._id;

  const [addVoteMutation, { loading }] = useMutation(ADD_VOTE);
  const onAddVote = async (optionId: string) => {
    try {
      if (optionId === isPollVoted) return;
      else if (!user?._id) {
        navigate("/login");
        return;
      } else if (data?.user?._id === user._id) return;
      console.log("add vote called.");
      // return;
      const {
        data: { AddVote },
      } = await addVoteMutation({
        variables: {
          optionId,
          pollId: data._id,
        },
      });
      if (AddVote.success) {
        successNotify(AddVote.message);
        refetch();
      } else {
        warnNotify(AddVote.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [removePollMutation] = useMutation(REMOVE_POLL);
  const onRemovePoll = async () => {
    try {
      const {
        data: { RemovePoll },
      } = await removePollMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (RemovePoll.success) {
        successNotify(RemovePoll.message);
        refetch();
      } else {
        warnNotify(RemovePoll.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const [addOrRemoveFollowerMutation] = useMutation(ADD_OR_REMOVE_POLL);
  const onAddOrRemoveFollower = async () => {
    try {
      const {
        data: { AddOrRemoveFollower },
      } = await addOrRemoveFollowerMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (AddOrRemoveFollower.success) {
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const isFollowing = data.followers.some((item) => item?._id === user?._id);

  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-4">
      <Spin spinning={loading}>
        <div
          className="bg-white rounded-md shadow-xl hover:shadow-2xl p-5 border relative"
          onClick={() => {
            console.log("sdfsdf sdf ");
          }}
        >
          {/* {data.user.email} */}
          <div className="absolute top-1 right-2" onClick={(e) => e.stopPropagation()}>
            {isMine && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                okButtonProps={{ className: "danger", danger: true }}
                onConfirm={onRemovePoll}
              >
                <i className="far fa-trash-alt text-red-600 mr-2"></i>
              </Popconfirm>
            ) }
            
            {!isMine && user?._id && (
              <span className="text-[#7C83C8] cursor-pointer" onClick={onAddOrRemoveFollower}>
                {/* {isFollowing ? "Unfollow" : "Follow"} */}
                <Tooltip placement="top" title={isFollowing ? "Unfollow" : "Follow"}>
                  {isFollowing ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
                </Tooltip>
              </span>
            )}
          </div>
          <div className="text-base  md:text-lg lg:text-2xl text-gray-700 mb-5">
            <div dangerouslySetInnerHTML={{ __html: data?.question }}></div>
          </div>
          <div className="text-gray-700 mb-5">
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
          </div>
          <div>
            {tags?.map(({ _id, text }) => (
              <Tag key={_id} className="mb-3">
                {text}
              </Tag>
            ))}
          </div>
          {options?.map((op) => (
            <PollOption
              {...op}
              key={op._id}
              isMine={isMine}
              isPollVoted={isPollVoted}
              totalVote={totalVote}
              onAddVote={onAddVote}
              refetch={refetch}
              pollId={data._id}
            />
          ))}
          {data?.suggestionable && !isMine && user?._id && <AddSuggession refetch={refetch} pollId={data._id} />}
        </div>
      </Spin>
    </div>
  );
};
