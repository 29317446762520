import React from "react";

interface QuestionBoxProps {
  setQues: React.Dispatch<React.SetStateAction<string>>;
  ques: string;
  oldPolls: any[];
  onBlur: () => void;
  validationError: string;
}

export const QuestionBox = ({ setQues, ques, oldPolls, onBlur, validationError }: QuestionBoxProps) => {
  const handleQues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length < 3000) {
      setQues(e.target.value);
    }
  };

  const questionCheck = () => {
    if (oldPolls && Array.isArray(oldPolls)) {
      if (oldPolls.find((poll) => poll.question === ques.trim())) {
        alert("Poll already exists!");
        setQues("");
      }
    } else {
      console.error("oldPolls is null or not an array:", oldPolls);
    }
    onBlur();
  };

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold mb-2">
        Question <span className="text-red-500">*</span>
        {validationError && <span className="text-red-500 text-sm font-normal ml-3">{validationError}</span>}
      </div>
      <div>
        <textarea
          className="w-full border font-bold border-gray-300 rounded-lg py-2 px-5 focus:border-gray-300 focus:border-2 outline-none min-h-[10px]"
          value={ques}
          onChange={handleQues}
          onBlur={questionCheck}
        />
        <div className="text-end text-gray-500">{ques.length}/300</div>
      </div>
    </div>
  );
};
