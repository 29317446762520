import gql from "graphql-tag";

export const USER_LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      code
      success
      message
      token
      user {
        _id
        firstname
        lastname
        avatar
        email
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        postalCode
        role
        isEmailVerified
        createdAt
      }
    }
  }
`;

export const USER_REGISTER = gql`
  mutation Register($userInput: RegistrationInput) {
    Register(userInput: $userInput) {
      code
      success
      message
      token
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation GoogleSignIn($idToken: String, $password: String) {
    GoogleSignIn(idToken: $idToken, password: $password) {
      code
      success
      message
      user {
        _id
        firstname
        lastname
        avatar
        email
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        postalCode
        role
        isEmailVerified
        createdAt
      }
      token
    }
  }
`;

export const API_KEYS = gql`
  mutation GetTokens {
    GetTokens {
      data
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation FacebookSignIn($token: String, $role: UserRole) {
    FacebookSignIn(token: $token, role: $role) {
      code
      success
      message
      user {
        _id
        firstname
        lastname
        avatar
        email
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        postalCode
        role
        isEmailVerified
        createdAt
      }
      token
    }
  }
`;

export const APPLE_LOGIN = gql`
  mutation SignInWithApple($idToken: String!, $firstName: String, $lastName: String) {
    SignInWithApple(idToken: $idToken, firstName: $firstName, lastName: $lastName) {
      code
      success
      message
      user {
        _id
        firstname
        lastname
        avatar
        email
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        postalCode
        role
        isEmailVerified
        createdAt
      }
      token
    }
  }
`;

export const CREATE_CLEAR_SESSION = gql`
  mutation CreateClearSession($authCode: String, $token: String) {
    CreateClearSession(authCode: $authCode, token: $token) {
      code
      success
      message
      _id
      token
    }
  }
`;

export const UPDATE_EMAIL_VERIFICATION = gql`
  mutation UpdateEmailVerification($token: String) {
    UpdateEmailVerification(token: $token) {
      code
      success
      message
      token
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($email: String!) {
    ForgetPassword(email: $email) {
      code
      success
      message
      user {
        _id
        isClearVerified
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($code: String!, $password: String!) {
    ResetPassword(securityCode: $code, newPassword: $password) {
      code
      success
      message
    }
  }
`;

export const FETCH_USER_BY_ID = gql`
  query FetchUserById($id: ID) {
    FetchUserById(id: $id) {
      code
      message
      user {
        firstname
        lastname
        username
        avatar
        email
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        role
      }
    }
  }
`;

export const FETCH_USER_BY_ID_OAUTH = gql`
  query FetchUserById($id: ID) {
    FetchUserById(id: $id) {
      code
      message
      user {
        firstname
        lastname
        username
        avatar
        email
        appleEmail {
          address
        }
        facebookEmail
        googleEmail
        race
        weight
        height
        gender
        age
        workOrEducation
        country
        locationState
        city
        role
      }
    }
  }
`;

export const PROFILE_UPDATE = gql`
  mutation ProfileUpdate($profileData: UserProfileUpdateInput!) {
    ProfileUpdate(profileData: $profileData) {
      code
      success
      message
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation ChangeEmail($password: String!, $newEmail: String!) {
    ChangeEmail(password: $password, newEmail: $newEmail) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation PasswordUpdate($oldPassword: String!, $newPassword: String!) {
    PasswordUpdate(oldPassword: $oldPassword, newPassword: $newPassword) {
      code
      message
      success
    }
  }
`;

export const REMOVE_OAUTH = gql`
  mutation RemoveOAuth($provider: String!) {
    RemoveOAuth(provider: $provider) {
      code
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    DeleteAccount {
      code
      success
      message
    }
  }
`;

export const FETCH_VERIFICATION_BY_TOKEN = gql`
  query FetchUserByToken($token: String) {
    FetchUserByToken(token: $token) {
      code
      message
      user {
        registrationId
        isClearVerified
      }
    }
  }
`;
