import { useEffect } from "react";
import { Container } from "../components";
import { Helmet } from "react-helmet";

export const Disclaimer = () => {
  let root = document.getElementById("root");
  useEffect(() => {
    let rootHeight = root.offsetHeight;
    const target = document.getElementById("get_height");
    console.log("called", target);
    // if (!target) return;
    let bodyHeight = window.innerHeight;
    let ownTakenHeight = target?.offsetHeight;

    let restHeight = bodyHeight - rootHeight;
    if (rootHeight < bodyHeight && target) {
      target.style.minHeight = `${restHeight + ownTakenHeight}px`;
    }
    // console.log({ rootHeight, bodyHeight, restHeight, ownTakenHeight });
  }, [root]);
  return (
    <>
      <Helmet>
        <title>Disclaimer - Pollsboard: Understanding Our Terms</title>
        <meta name="description" content="Read the Pollsboard.com disclaimer to understand the terms and conditions of using our platform, including accuracy of information, external links, and user conduct." />
        <meta name="keywords" content="disclaimer, Pollsboard, terms and conditions, accuracy of information, external links, user conduct" />
      </Helmet>
      <section id="card" className="bg-slate-200 py-12">
        <Container>
          <h2 className="text-xl text-center border-b-2 border-gray-800 mb-5 pb-2 text-gray-800">Disclaimer</h2>
          <p className="text-lg mb-5 font-normal text-gray-800">
            Welcome to Pollsboard.com, a website that provides users with access to various polls, surveys, and other
            interactive content. Before using Pollsboard.com, please carefully read and understand the following
            disclaimer. By accessing and using this website, you agree to be bound by the terms and conditions set forth
            in this disclaimer.
          </p>
          <ol style={{ listStyle: "auto", padding: "revert" }}>
            <li>
              <p className="text-lg mb-5 font-normal text-gray-800">
                Accuracy of Information:<br></br>
                We make every effort to ensure that the information provided on Pollsboard.com is accurate and up-to-date.
                However, we do not guarantee the accuracy, completeness, or reliability of any information on this
                website. Users are advised to verify the accuracy of any information provided on this website before
                acting on it.
              </p>
            </li>
            <li>
              <p className="text-lg mb-5 font-normal text-gray-800">
                Third-Party Content:<br></br>
                Pollsboard.com may contain links to third-party websites or resources that are not under our control. We
                are not responsible for the content, accuracy, or reliability of any such third-party websites or
                resources. The inclusion of any links does not imply our endorsement of the website or resource.
              </p>
            </li>
            <li>
              <p className="text-lg mb-5 font-normal text-gray-800">
                Use of Content:<br></br> All content provided on Pollsboard.com is for informational purposes only. The
                content on this website should not be used as a substitute for professional advice or guidance. We are not
                responsible for any damages or losses resulting from the use of information on this website.
              </p>
            </li>
            <li>
              <p className="text-lg mb-5 font-normal text-gray-800">
                User Conduct:<br></br> Users of Pollsboard.com are solely responsible for their conduct on this website.
                Users agree to use this website in accordance with all applicable laws and regulations. Users may not use
                this website to engage in any conduct that is unlawful, harmful, or offensive.
              </p>
            </li>
            <li>
              <p className="text-lg mb-5 font-normal text-gray-800">
                Modification of Terms: <br></br>Pollsboard.com may modify this disclaimer at any time without prior
                notice. Users are advised to review this disclaimer periodically for any changes.
              </p>
            </li>
          </ol>
          <p className="text-lg mb-5 font-normal text-gray-800">
            By accessing and using Pollsboard.com, you acknowledge that you have read, understood, and agree to be bound
            by this disclaimer. If you do not agree to be bound by this disclaimer, please do not use this website.
          </p>
          <div id="get_height"></div>
        </Container>
      </section>
    </>
  );
};
