import { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_ACCOUNT, REMOVE_OAUTH } from "../../graphql/models";
import { Popconfirm, Spin, Button, Card, Typography, Space } from "antd";
import { ChangePassword } from "./ChangePassword";
import { useAppDispatch } from "../../hooks";
import { successNotify } from "../../util";
import { onUserLogout } from "../../store/slices/auth.slice";
import {
  DeleteOutlined,
  KeyOutlined,
  GoogleOutlined,
  AppleOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

interface OAuthData {
  provider: string;
  email: string;
}

export const OtherAccountInfo = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({
    email: "",
    facebookEmail: "",
    googleEmail: "",
    appleEmail: "",
  });

  const dispatch = useAppDispatch();
  const [AccountDeleteMutation, { loading: deleteLoading }] = useMutation(DELETE_ACCOUNT);
  const [RemoveOAuthMutation, { loading: removeOAuthLoading }] = useMutation(REMOVE_OAUTH);

  useEffect(() => {
    if (data?.data?.FetchUserById?.user) {
      const userData = data?.data?.FetchUserById?.user || {};
      setState((prevState) => ({
        ...prevState,
        email: userData.email || "",
        facebookEmail: userData.facebookEmail || "",
        googleEmail: userData.googleEmail || "",
        appleEmail: userData.appleEmail.address || "",
      }));
    }
  }, [data]);

  const onDeleteAccount = async () => {
    try {
      const {
        data: { DeleteAccount },
      } = await AccountDeleteMutation();
      if (DeleteAccount.success) {
        successNotify(DeleteAccount.message);
        dispatch(onUserLogout({}));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onRemoveOAuth = async (oAuth: OAuthData) => {
    try {
      const {
        data: { RemoveOAuth },
      } = await RemoveOAuthMutation({ variables: { provider: oAuth.provider } });
      if (RemoveOAuth.success) {
        successNotify(RemoveOAuth.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const userOAuthAccounts = [
    { provider: "facebook", email: state.facebookEmail, icon: <FacebookOutlined /> },
    { provider: "google", email: state.googleEmail, icon: <GoogleOutlined /> },
    { provider: "apple", email: state.appleEmail, icon: <AppleOutlined /> },
  ].filter((account) => account.email);

  return (
    <Spin spinning={deleteLoading || removeOAuthLoading}>
    <div className="mb-2 bg-slate-100 min-h-screen py-8 px-6">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        
        {/* Email and Password Section */}
        <Card
          title="Account Information"
          bordered={false}
          className="shadow-lg bg-white"
          bodyStyle={{ padding: "20px", borderRadius: "8px" }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-4">
            <div className="flex flex-col sm:flex-row items-center gap-2 text-base text-gray-700 font-semibold mb-2">
              <Text strong>Email: </Text>
              <Text>{state.email}</Text>
            </div>
            
            <div className="flex flex-col sm:flex-row items-center gap-2 text-base text-gray-700 font-semibold mb-2">
              <Text strong>Password: </Text>
              <Button
                icon={<KeyOutlined />}
                type="link"
                className="text-blue-600 hover:text-blue-500"
                onClick={() => setVisible(true)}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Card>

        <Card
          title="Social Accounts"
          bordered={false}
          className="shadow-lg bg-white"
          bodyStyle={{ padding: "20px", borderRadius: "8px" }}
        >
          {userOAuthAccounts.length ? (
            userOAuthAccounts.map((account) => (
              <div
                key={account.provider}
                className="flex flex-col md:flex-row md:justify-between text-base text-gray-700 font-semibold mb-8"
              >
                <div className="flex items-center mb-2 md:mb-0 space-x-2">
                  {account.icon}
                  <div className="flex flex-col md:flex-row">
                  <Text strong>{`${account.provider.charAt(0).toUpperCase() + account.provider.slice(1)} Login Email:`}</Text>
                  <Text>{account.email}</Text>
                  </div>
                </div>
                <Popconfirm
                okText="Delete"
                okType="danger"
                onConfirm={() => onRemoveOAuth(account)}
                title="Your OAuth account will be deleted, are you sure?"
              >
                <Button
                  danger
                  type="primary"
                  className="bg-red-500 hover:bg-red-600 text-white w-48"
                >
                  Remove {account.provider.charAt(0).toUpperCase() + account.provider.slice(1)}
                </Button>
                </Popconfirm>
              </div>
            ))
          ) : (
            <Text type="secondary">You have not connected any social accounts.</Text>
          )}
        </Card>

        <Card
          title="Danger Zone"
          bordered={false}
          className="shadow-lg bg-white"
          bodyStyle={{ padding: "20px", borderRadius: "8px" }}
        >
          <Popconfirm
            okText="Delete"
            okType="danger"
            onConfirm={onDeleteAccount}
            title="All your polls will be deleted, are you sure?"
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              type="primary"
              size="large"
              className="flex items-center bg-red-500 hover:bg-red-600 text-white"
            >
              Delete Account
            </Button>
          </Popconfirm>
        </Card>

      </Space>
    </div>
  </Spin>
);
};
