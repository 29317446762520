import { useEffect } from "react";

function useFixHight(id: string, len: number): void {
  const target = document.getElementById(id);

  useEffect(() => {
    if (!target) return;
    let takenHeight = document.getElementById("root").offsetHeight;
    let bodyHeight = window.innerHeight;
    let ownTakenHeight = target?.offsetHeight;

    let restHeight = bodyHeight - takenHeight;
    if (takenHeight < bodyHeight) target.style.height = `${restHeight}px`;

    console.log({ takenHeight, bodyHeight, restHeight, ownTakenHeight });
  }, [id, len]);
}

export default useFixHight;
