import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./routes";
import { ReduxProvider } from "./store/provider";
import "./styles/index.scss";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/client";

const App: React.FC = () => (
  // <React.StrictMode>
  <ReduxProvider>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AppRouter />
      </ApolloProvider>
    </BrowserRouter>
  </ReduxProvider>
  // </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <div className="bg-slate-200">
    <App />
  </div>
);
