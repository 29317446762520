import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_EMAIL_VERIFICATION } from "../graphql/models";
import { onUserLogout } from "../store/slices/auth.slice";
import { useDispatch } from "react-redux";
import { successNotify } from "../util";

export const VerifyEmail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [VerifyEmailMutaion, { data, loading }] = useMutation(UPDATE_EMAIL_VERIFICATION);
  const emailVerification = async (token) => {
    try {
      await VerifyEmailMutaion({ variables: { token } });
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.UpdateEmailVerification.code === "UPDATED") {
      successNotify("Your email has been verified!");
      navigate(`/register?code=${data.UpdateEmailVerification.token}`);
    }
  }, [data]);
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("code");
    if (token) {
      dispatch(onUserLogout({}));
      emailVerification(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 50,
        textAlign: "center",
        background: "#444",
        height: "100vh",
      }}
    >
      {loading && (
        <div className="rgt-signup-txts">
          {/* <ScaleLoader color="#fff" size={200} /> */}
          <h1 className="text-white">Verifying your account...</h1>
        </div>
      )}

      {data &&
        (data.UpdateEmailVerification?.code === "UPDATED" ? (
          <div className="rgt-signup-txts">
            {/* <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize: 150 }} /> */}
            <h1 className="text-white mt-3">{data?.UpdateEmailVerification?.msg}</h1>
          </div>
        ) : (
          <div className="rgt-signup-txts">
            {/* <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" style={{ fontSize: 150 }} /> */}
            <h1 className="text-white mt-3">{data?.UpdateEmailVerification?.msg}</h1>
          </div>
        ))}

      {!data && !loading && (
        <div className="rgt-signup-txts">
          {/* <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" style={{ fontSize: 150 }} /> */}
          <h1 className="text-white mt-3">No security code provided.</h1>
        </div>
      )}
    </div>
  );
};
