import { useEffect } from "react";
import { Container } from "../components";
import { Helmet } from "react-helmet";

export const TermsAndCondition = () => {
  let root = document.getElementById("root");
  useEffect(() => {
    let rootHeight = root.offsetHeight;
    const target = document.getElementById("get_height");
    console.log("called", target);
    // if (!target) return;
    let bodyHeight = window.innerHeight;
    let ownTakenHeight = target?.offsetHeight;

    let restHeight = bodyHeight - rootHeight;
    if (rootHeight < bodyHeight && target) {
      target.style.minHeight = `${restHeight + ownTakenHeight}px`;
    }
    // console.log({ rootHeight, bodyHeight, restHeight, ownTakenHeight });
  }, [root]);
  return (
    <>
    <Helmet>
      <title>Terms and Conditions | Pollsboard.com</title>
      <meta name="description" content="Read the terms and conditions governing your use of Pollsboard.com, including user responsibilities, content policies, and dispute resolution." />
      <meta name="keywords" content="terms and conditions, user agreement, Pollsboard, user responsibilities, content policies, dispute resolution" />
    </Helmet>
    <section id="card" className="bg-slate-200 py-12">
      <Container>
        <h2 className="text-xl text-center border-b-2 border-gray-800 mb-5 pb-2 text-gray-800">Terms and Conditions</h2>
        <p className="text-lg mb-5 font-normal text-gray-800">
          Welcome to Pollsboard.com! Before using our website, please read these Terms and Conditions carefully as they
          govern your use of the site.
          These Terms and Conditions ("Agreement") constitute a legal agreement between you ("User") and Agreeable LLC ("Agreeable")
          regarding the use of the website Pollsboard.com ("Site"), which is owned and operated by Agreeable as its subsidiary.
        </p>
        <ol style={{ listStyle: "auto", padding: "revert" }}>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Acceptance of Terms: By using Pollsboard.com, you agree to be bound by these Terms and Conditions, which
              may be updated or amended from time to time without prior notice. Please check this page periodically for
              changes.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Eligibility: You must be at least 16 years of age and a resident of the United States of America to use our website.
              If you are under 16 years old, please do not use this site.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              User Conduct: You agree to use Pollsboard.com only for lawful purposes and in accordance with this Agreement.
              You may not use the Site in any way that violates any applicable laws or regulations or infringes upon the rights of others.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              User Content: Pollsboard.com allows users to create, share, and view polls. You are solely responsible for
              the content you post on the site, and you represent and warrant that you have all necessary rights to use,
              reproduce, and distribute such content. By submitting content to the site, you grant Pollsboard.com a
              non-exclusive, worldwide, royalty-free, transferable, sub-licensable license to use, reproduce, modify,
              adapt, publish, translate, distribute, and display such content in any form, medium, or technology now
              known or later developed.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Intellectual Property: All content and materials on Pollsboard.com, including text, graphics, logos, images,
              and software, are owned or licensed by Agreeable and are protected by copyright, trademark, and other intellectual property laws.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Disclaimer of Warranties: Agreeable provides Pollsboard.com on an "as is" and "as available" basis without
              any warranties of any kind, express or implied, including but not limited to warranties of
              merchantability, fitness for a particular purpose, non-infringement, or course of performance.
              Agreeable does not warrant that the site will be error-free, uninterrupted, or secure.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Limitation of Liability: Agreeable shall not be liable for any direct, indirect, incidental, special,
              or consequential damages arising out of or in connection with your use of the site or the inability to use
              the site, even if Agreeable has been advised of the possibility of such damages.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Indemnification: You agree to indemnify, defend, and hold harmless Agreeable, its affiliates, and
              their respective officers, directors, employees, and agents from any and all claims, damages, expenses, or
              losses arising out of your use of the site or your violation of these Terms and Conditions.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Governing Law: These Terms and Conditions shall be governed by and construed in accordance with the laws
              of the State of California, without giving effect to any principles of conflicts of law.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Dispute Resolution: Any dispute arising out of or in connection with these Terms and Conditions shall be
              resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The
              arbitration shall take place in San Francisco, California.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Termination: Agreeable reserves the right to terminate your access to Pollsboard.com at any time for any
              reason, without prior notice.
            </p>
          </li>
          <li>
            <p className="text-lg mb-5 font-normal text-gray-800">
              Entire Agreement: This Agreement constitutes the entire agreement between User and Agreeable regarding the use of
              Pollsboard.com and supersedes all prior or contemporaneous communications and proposals, whether oral or written.
            </p>
          </li>
        </ol>
        <p className="text-lg mb-5 font-normal text-gray-800">
            By using Pollsboard.com, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
            If you do not agree to any provision of this Agreement, please do not use the Site.
        </p>
        <div id="get_height"></div>
      </Container>
    </section>
    </>
  );
};
