import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import { ADD_COMMENT, FETCH_COMMENT, DELETE_COMMENT, EDIT_COMMENT, FETCH_USER_BY_ID } from "../../graphql/models";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { warnNotify } from "../../util";
import { useSelector } from "react-redux";

const Comments = ({ currentUserId, pollId, questionId }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [ascending, setAscending] = useState(true);
  const [commentCount, setCommentCount] = useState(0);
  const rootComments = backendComments.filter((backendComment) => backendComment.parentId === null);
  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
  console.log("questionId", questionId);
  const [AddCommentMutation] = useMutation(ADD_COMMENT);
  const [CommentDeleteMutation] = useMutation(DELETE_COMMENT);
  const [CommentEditMutation] = useMutation(EDIT_COMMENT);
  const { data, refetch } = useQuery(FETCH_COMMENT, {
    variables: { pollId: pollId, questionId: questionId },
    pollInterval: 0, // how often do we want to refetch?
    onCompleted: (data) => {
      setBackendComments(data?.FetchComment?.comments || []);
      setCommentCount(data?.FetchComment?.count || 0);
    },
  });
  const comments = data?.FetchComment?.comments || [];
  const count = data?.FetchComment?.count || null;

  const user = useSelector((state) => state?.auth?.user);

  const { data: userData } = useQuery(FETCH_USER_BY_ID, {
    variables: { id: user?._id },
  });

  const addComment = async (text, parentId) => {
    try {
      console.log(userData);
      const {
        data: { AddComment },
      } = await AddCommentMutation({
        variables: {
          commentInput: {
            body: text,
            parentId: parentId,
            username: userData?.FetchUserById?.user.username,
            pollId: pollId,
            questionId: questionId,
          },
        },
      });
      if (AddComment.success) {
        setBackendComments(AddComment.comments);
        setCommentCount(AddComment.count);
      } else {
        warnNotify(AddComment.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    setActiveComment(null);
  };

  const updateComment = async (text, commentId) => {
    try {
      const {
        data: { EditComment },
      } = await CommentEditMutation({
        variables: {
          commentID: commentId,
          updatedComment: text,
          pollId: pollId,
        },
      });
      if (EditComment.success) {
        setBackendComments(EditComment.comments);
        setCommentCount(EditComment.count);
        setActiveComment(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const deleteComment = async (commentId) => {
    try {
      const {
        data: { DeleteComment },
      } = await CommentDeleteMutation({
        variables: {
          commentID: commentId,
          pollId: pollId,
        },
      });
      if (DeleteComment.success) {
        setBackendComments(DeleteComment.comments);
        setCommentCount(DeleteComment.count);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const likeComment = async (commentId) => {
    try {
      const {
        data: { EditComment },
      } = await CommentEditMutation({
        variables: {
          commentID: commentId,
          likes: currentUserId,
          pollId: pollId,
        },
      });
      if (EditComment.success) {
        setBackendComments(EditComment.comments);
        setCommentCount(EditComment.count);
        setActiveComment(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (data) {
      setBackendComments(comments);
      setCommentCount(count);
    } else {
      refetch(); // Fetch comments on initial load
    }
  }, [data]); // don't put comments in dependency array.  it will make it refetch every few milliseconds.

  const commentList = ascending ? rootComments.slice().reverse() : rootComments;

  return (
    <section style={{ marginTop: "2%" }} aria-label="Comments section">
      <header style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <h3 style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "5px" }}>{commentCount} Comments</h3>
        <div style={{ marginLeft: "1%" }}>
          <select
            style={{ fontSize: "14px" }}
            onChange={(event) => setAscending(event.target.value === "true")}
            aria-label="Sort comments"
          >
            <option value={true}>Newest first</option>
            <option value={false}>Oldest first</option>
          </select>
        </div>
      </header>
      <CommentForm submitLabel="Write" handleSubmit={addComment} />
      <div className="comments-container" role="region" aria-live="polite">
        {commentList.map((rootComment) => (
          <Comment
            key={rootComment._id}
            comment={rootComment}
            replies={getReplies(rootComment._id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            likeComment={likeComment}
            currentUserId={currentUserId}
            currentUserName={userData?.FetchUserById?.user?.username}
          />
        ))}
      </div>
    </section>
  );
};

export default Comments;
