import gql from "graphql-tag";

export const UPDATE_USERNAME = gql`
  mutation updateUsername($email: String!, $username: String!) {
    updateUsername(email: $email, username: $username) {
      code
      success
      message
    }
  }
`;

export const CreateClearSession = gql`
  mutation CreateClearSession($authCode: String!, $token: String!) {
    FinishClearSession(authCode: $authCode, token: $token) {
      code
      success
      message
    }
  }
`;
