import { useMutation } from "@apollo/react-hooks";
import { useState } from "react";
import { FORGET_PASSWORD } from "../graphql/models";
import { notify } from "../util";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [ForgetPasswordMutation, { loading }] = useMutation(FORGET_PASSWORD);
  const onSubmit = async () => {
    try {
      const {
        data: { ForgetPassword },
      } = await ForgetPasswordMutation({
        variables: {
          email,
        },
      });
      notify(ForgetPassword);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <div className="py-12 grid place-items-center">
        <div className="px-6 md:w-[550px] sm:w-ful w-full">
          <div className="bg-white rounded-lg shadow-xl hover:2xl p-6">
            <h2 className="text-center text-gray-700 font-bold text-4xl pb-8">Forgot password</h2>
            <div className="mb-8">
              <div className="text-base text-gray-700 font-semibold mb-2">Email</div>
              <input
                className="border focus:border w-full rounded-lg px-4 py-2"
                type="text"
                placeholder="Email address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              className="w-full py-2 rounded-lg font-semibold bg-blue-500 hover:bg-blue-700  text-white text-xl cursor-pointer disabled:bg-blue-300"
              disabled={!email}
              onClick={onSubmit}
            >
              {loading ? "Sending..." : "Send Link"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
