interface OptionProps {
  data: any;
  idx: number;
  onDeleteOption: (idx: number) => void;
}

export const Option = ({ data, idx, onDeleteOption}: OptionProps) => {
  return (
    <div className="grid grid-cols-12 mb-5" tabIndex={0}>
      <div className="col-span-12 sm:col-span-6 md:col-span-4 group relative">
        <div className="p-3 bg-slate-100 border font-bold">
          {idx + 1}. {data}
        </div>
        <div className="hidden group-hover:flex absolute w-full top-0 h-full bg-slate-200/75 justify-center items-center">
          <i className="fas fa-trash-alt p-1 cursor-pointer text-red-600" onClick={() => onDeleteOption(idx)}></i>
        </div>
      </div>
    </div>
  );
};
