import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADD_OPTION } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";
import { Spin } from "antd";

export const AddSuggession = ({ refetch, pollId }) => {
  const [hideInput, setHideInput] = useState(true);
  const [answerText, setAnswerText] = useState("");

  const [addOptionMutation, { loading }] = useMutation(ADD_OPTION);
  const onAddOption = async () => {
    try {
      const {
        data: { AddOption },
      } = await addOptionMutation({
        variables: {
          pollId,
          text: answerText,
        },
      });
      if (AddOption.success) {
        successNotify(AddOption.message);
        setHideInput(true);
        setAnswerText("");
        refetch();
      } else {
        warnNotify(AddOption.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onCancel = () => {
    setHideInput(!hideInput);
    setAnswerText("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && answerText) {
      onAddOption();
    }
  };

  return (
    <section
      className="col-span-12 sm:col-span-6 md:col-span-4"
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-label="Add new poll suggestion"
    >
      <Spin spinning={loading} aria-label="Loading suggestion input">
        {hideInput ? (
          <button
            className="p-3 bg-white cursor-pointer border-[2px] border-gray-400 rounded-lg font-bold text-center"
            onClick={() => setHideInput(!hideInput)}
            aria-label="Open suggestion input"
          >
            + Add suggestion
          </button>
        ) : (
          <div className="relative">
            <input
              className="w-full border border-gray-400 px-3 py-3 rounded-lg"
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type suggession"
              autoFocus
              aria-label="Type your suggestion ehre"
            />
            <div className="absolute w-[100px] -top-[20px] right-0 text-end">
              <button
                className="fas fa-times mr-2 p-1 cursor-pointer text-red-600"
                onClick={onCancel}
                aria-label="Cancel"
              >
                <span className="sr-only">Cancel</span>
              </button>
              <button
                className="disabled:opacity-80"
                onClick={onAddOption}
                disabled={!answerText}
                aria-label="Submit suggestion"
              >
                <i className="fas fa-check p-1"></i>
                <span className="sr-only">Submit suggestion</span>
              </button>
            </div>
          </div>
        )}
      </Spin>
    </section>
  );
};
