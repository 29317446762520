import { useMutation } from "@apollo/react-hooks";
import { Progress, Spin, Tag } from "antd";
import { REMOVE_OPTION, UPDATE_POLL_OPTION } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";

export const PollOption = ({
  _id,
  voteCount,
  text,
  totalVote,
  isMine,
  isPollVoted,
  onAddVote,
  accepted,
  refetch,
  pollId,
}) => {
  // const shouldShow = isMine || isPollVoted;
  const isOptionVoted = isPollVoted === _id;

  const [removeOptionMutation, { loading: rmLoading }] = useMutation(REMOVE_OPTION);
  const onRemoveOption = async () => {
    try {
      const {
        data: { RemoveOption },
      } = await removeOptionMutation({
        variables: {
          pollId,
          optionId: _id,
        },
      });
      if (RemoveOption.success) {
        successNotify(RemoveOption.message);
        refetch();
      } else {
        warnNotify(RemoveOption.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [updatePolloptionsMutation, { loading: upLoading }] = useMutation(UPDATE_POLL_OPTION);
  const onUpdatePolloptions = async () => {
    try {
      const {
        data: { UpdatePolloptions },
      } = await updatePolloptionsMutation({
        variables: {
          pollId,
          optionId: _id,
          accepted: true,
        },
      });
      if (UpdatePolloptions.success) {
        successNotify(UpdatePolloptions.message);
        refetch();
      } else {
        warnNotify(UpdatePolloptions.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  if (!accepted && !isMine) return;

  const votePercentage = totalVote ? Number(((voteCount / totalVote) * 100).toFixed(2)) : 0;

  return (
    <Spin spinning={rmLoading || upLoading} aria-label={`Loading status for option: ${text}`}>
      {/* <div
        className={`mb-5 border-2 rounded-md cursor-pointer ${
          isOptionVoted ? "border-[#7C83C8]" : "border-[#E6E6E6]"
        } p-2`}
        onClick={(e) => {
          e.stopPropagation();
          onAddVote(_id);
        }}
      > */}
      <div
        className={`mb-5 border-2 rounded-md ${isOptionVoted ? "border-[#7C83C8]" : "border-[#E6E6E6]"} p-2 ${
          isOptionVoted ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (!isOptionVoted) {
            onAddVote(_id);
          }
        }}
        aria-label={`Vote for option: ${text}`}
        role="button"
        tabIndex={0}
      >
        {!accepted && (
          <Tag color="magenta" className="mb-1" aria-label="New suggestion tag">
            New suggestion
          </Tag>
        )}
        <div className="flex justify-between items-center mb-0">
          <div className="flex items-center">
            {!isMine && (
              <input
                type="radio"
                checked={isOptionVoted}
                readOnly
                className="mr-2"
                aria-label={`Select option: ${text}`}
              />
            )}
            <p className="font-semibold text-base">{text}</p>
          </div>
          {/* percentage */}
          {accepted && (
            <p className="mr-2 text-sm" aria-hidden="true">
              {votePercentage}%
            </p>
          )}
          {/* accept or deletion */}
          {!accepted && (
            <div className="text-end">
              <i
                className="fas fa-times mr-3 cursor-pointer text-red-600"
                onClick={onRemoveOption}
                aria-label="Remove this poll option"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") onRemoveOption();
                }}
              ></i>
              <i
                className="fas fa-check"
                onClick={onUpdatePolloptions}
                aria-label="Accept this poll option"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") onRemoveOption();
                }}
              ></i>
            </div>
          )}
        </div>
        {/* progress bar */}
        {accepted && (
          <Progress
            className="my-0 mx-0"
            percent={votePercentage}
            status="active"
            showInfo={false}
            strokeColor={isOptionVoted ? "#7C83C8" : "#c4c1c1"}
            size="small"
            trailColor="#EFEFEF"
            aria-label={`Voting progress: ${votePercentage}%`}
          />
        )}
      </div>
    </Spin>
  );
};
