// import { Drawer } from "antd";
import React from "react";
import { Sidebar } from "./Sidebar";

interface PrivateLayoutType {
  children: React.ReactNode;
}


export const PrivateLayout = ({ children }: PrivateLayoutType) => {
  // const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="flex bg-slate-100 min-h-screen">
        <div className="hidden h-[85vh] mt-5 md:block w-[250px] bg-white border-r-2 sticky top-[70px]">
          <Sidebar />
        </div>
        <div className="w-full mb-2">
          {/* <div className="md:hidden pt-5 pb-0 px-5">
            <i className="fas fa-bars" onClick={() => setOpen(!open)}></i>
            <Drawer
              headerStyle={{ display: "none" }}
              bodyStyle={{ padding: "0" }}
              open={open}
              placement="left"
              onClose={() => setOpen(!open)}
              width="200"
            >
              <Sidebar />
            </Drawer>
          </div> */}
          {children}
        </div>
      </div>
    </div>
  );
};
