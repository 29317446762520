
import { useEffect } from "react";
import { Container } from "../components";
import { Helmet } from "react-helmet"; // Assuming React Helmet is used for dynamic meta tags

export const AboutUs = () => {
  let root = document.getElementById("root");
  useEffect(() => {
    let rootHeight = root.offsetHeight;
    const target = document.getElementById("get_height");
    console.log("called", target);
    let bodyHeight = window.innerHeight;
    let ownTakenHeight = target?.offsetHeight;

    let restHeight = bodyHeight - rootHeight;
    if (rootHeight < bodyHeight && target) {
      target.style.minHeight = `${restHeight + ownTakenHeight}px`;
    }
    console.log({ rootHeight, bodyHeight, restHeight, ownTakenHeight });
  }, [root]);
  return (
    <>
      <Helmet>
        <title>About Us - Pollsboard: Empowering Voices Through Verified Polling</title>
        <meta name="description" content="Discover Pollsboard's mission to empower individual voices through verified polling, emphasizing authenticity, integrity, and privacy. Learn about our commitment to democratizing digital dialogue." />
        <meta name="keywords" content="Pollsboard, verified polling, digital dialogue, empowerment, authenticity, integrity, privacy, question and answer platform" />
      </Helmet>
      <section className="bg-slate-200 py-12">
        <Container>
          <h1 className="text-xl text-center border-b-2 border-gray-800 mb-5 pb-2 text-gray-800">About Us</h1>
          <p className="text-lg mb-5 font-normal text-gray-800">
            Welcome to Pollsboard, where every voice matters. Our platform is dedicated to giving people a microphone to their communities, enabling meaningful discussions that shape the future of our society.
          </p>
          <p className="text-lg mb-5 font-normal text-gray-800"><strong>Our Mission</strong></p>
          <p className="text-lg mb-5 font-normal text-gray-800">
            At Pollsboard, we are committed to democratizing digital dialogue. We strive to uncover true public opinion, offering a platform where diverse voices come together to share their perspectives. By harnessing the power of big data binning, we aim to revolutionize communication between individuals and their communities, making it more inclusive, insightful, and impactful.
          </p>
          <p className="text-lg mb-5 font-normal text-gray-800"><strong>Our Vision</strong></p>
          <p className="text-lg mb-5 font-normal text-gray-800">
            We envision a world where every individual feels heard, valued, and empowered to influence the decisions that affect their lives. Pollsboard is more than just a polling platform; it’s a community-driven space where people connect, converse, and collaborate to create a shared future.
          </p>
          <p className="text-lg mb-5 font-normal text-gray-800"><strong>Functionality</strong></p>
          <p className="text-lg mb-5 font-normal text-gray-800">
            Pollsboard offers a unique space for users to engage in a variety of polls, contributing to a collective intelligence that provides a broad view of communal thoughts. Beyond polling, we encourage users to participate in positive, constructive conversations in our comments section. We believe that these discussions are key to understanding diverse viewpoints and fostering a more connected society.
          </p>
          <p className="text-lg mb-5 font-normal text-gray-800"><strong>Commitment to Integrity</strong></p>
          <p className="text-lg mb-5 font-normal text-gray-800">
            Integrity is at the core of everything we do. That’s why we’ve partnered with CLEAR, a global leader in user identification, to ensure that all our users are real people. We understand that the reliability of our platform depends on the authenticity of our users, so we’ve made it our top priority to safeguard this authenticity.
          </p>
          <p className="text-lg mb-5 font-normal text-gray-800"><strong>Join Us</strong></p>
          <p className="text-lg mb-5 font-normal text-gray-800">
            Become a part of Pollsboard today and contribute to a more informed and engaged community. Together, we can make a difference, one poll at a time.
          </p>
        </Container>
      </section>
    </>
  );
};
