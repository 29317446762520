import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Modal } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { USER_REGISTER } from "../../graphql/models";
import { Button } from "../Shared";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const [showText, setShowText] = useState(false);
  const [userInput, setUserInput] = useState({
    // firstname: "",
    // lastname: "",
    email: "",
    password: "",
  });

  const [cPassword, setCpassword] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const [RegisterMutation, { loading }] = useMutation(USER_REGISTER);

  const showNotification = ({ title, showNavigate }) => {
    Modal.info({
      title,
      icon: <ExclamationCircleOutlined />,
      // content: Register.message,
      // okText: 'OK',
      open: false,
      okButtonProps: {
        className: "ok-button",
        autoFocus: false,
        onClick: () => {
          if (showNavigate) {
            navigate("/login");
          }
          Modal.destroyAll();
        },
      },

      // cancelText: '取消',
    });
  };
  const handleSubmit = async () => {
    try {
      const {
        data: { Register },
      } = await RegisterMutation({
        variables: {
          userInput,
        },
      });
      // notify(Register);
      if (Register.success) {
        // showNotification({title: Register.message, showNavigate: false});
        setShowText(true);
      } else {
        if (Register.code === "EXIST") {
          showNotification({ title: Register.message, showNavigate: true });
          // setTimeout(() => {
          //   navigate('/login')
          // }, 2000);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const disable =
    !userInput.email ||
    // !userInput.firstname ||
    // !userInput.lastname ||
    !userInput.password ||
    userInput.password !== cPassword;

  return (
    <div className="shadow-lg rounded-lg bg-white p-5 px-10 w-full mt-5">
      {showText ? (
        <div className="min-h-[100px] flex items-center">
          <ExclamationCircleOutlined style={{ fontSize: "25px", color: "#3A6F9D" }} />
          <p className="font-semibold ml-3">
            Please check your email to verify before completing registration process.
          </p>
        </div>
      ) : (
        <>
          <div className="mb-5">
            <div className="text-base text-gray-700 font-semibold mb-2">Email</div>
            <input
              className="border focus:border w-full rounded-lg px-4 py-2"
              type="email"
              placeholder="Email address"
              name="email"
              onChange={onChange}
              value={userInput.email}
            />
          </div>
          <div className="mb-5">
            <div className="text-base text-gray-700 font-semibold mb-2">Password</div>
            <input
              className="border focus:border w-full rounded-lg px-4 py-2"
              type="password"
              placeholder="Password"
              name="password"
              onChange={onChange}
              value={userInput.password}
            />
          </div>
          <div className="mb-5">
            <div className="text-base text-gray-700 font-semibold mb-2">Confirm Password</div>
            <input
              className="border focus:border w-full rounded-lg px-4 py-2"
              type="password"
              placeholder="******"
              name="password"
              onChange={(e) => setCpassword(e.target.value)}
              value={cPassword}
            />
          </div>
          <p className="mb-3 text-red-500">
            *Available in the USA only
          </p>
          <p className="mb-3 text-sm">
            {/* People who use our service may have uploaded your contact information to Facebook. Learn more.
        <br /> */}
            By clicking Register, you agree to our{" "}
            <Link to={"/privacy-policy"} className="text-blue-600">
              privacy policy
            </Link>
            ,{" "}
            <Link to={"/terms-and-conditions"} className="text-blue-600">
              terms and conditions
            </Link>
            ,{" "}
            <Link to={"/disclaimer"} className="text-blue-600">
              website disclaimer
            </Link>
            .
          </p>
          <Button
            className="w-full py-2 rounded-lg font-semibold bg-blue-500 hover:bg-blue-700  text-white text-xl cursor-pointer mb-3"
            disabled={disable || loading}
            onClick={handleSubmit}
            loading={loading}
            text="Register"
          />
        </>
      )}
    </div>
  );
};
