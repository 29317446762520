import { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_USERNAME } from "../../graphql/models";
import { Spin, Card, Typography, Input, Space, Button } from "antd";
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const BasicInfo = ({ data, loading }) => {
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    username: "",
    city: "",
    locationState: "",
    age: "",
  });

  const [newUsername, setNewUsername] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const [UsernameMutation] = useMutation(UPDATE_USERNAME);

  const updateData = async () => {
    try {
      const { data } = await UsernameMutation({
        variables: {
          email: state.email,
          username: newUsername,
        },
      });
      setIsUpdated(true);
      setTimeout(() => setIsUpdated(false), 3000);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (data) {
      const userData = data?.FetchUserById?.user || {};
      setState(userData);
      setNewUsername(userData.username || "");
    }
  }, [data]);

  return (
    <Spin spinning={loading}>
      <div className="bg-slate-100 pt-8 px-6">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Card
            title="Basic Information"
            bordered={false}
            className="shadow-lg bg-white"
            bodyStyle={{ padding: "20px", borderRadius: "8px" }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4">
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>First Name:</Text>
                <Text>{state.firstname || "N/A"}</Text>
              </div>
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>Last Name:</Text>
                <Text>{state.lastname || "N/A"}</Text>
              </div>
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>Gender:</Text>
                <Text>{state.gender || "N/A"}</Text>
              </div>
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>Age:</Text>
                <Text>{state.age || "N/A"}</Text>
              </div>

              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>Username:</Text>
                <Input
                  prefix={<EditOutlined />}
                  value={newUsername}
                  onChange={(event) => {
                    setNewUsername(event.target.value);
                    if (isUpdated) setIsUpdated(false);
                  }}
                  onBlur={updateData}
                  className="border border-gray-300 focus:border-blue-500 w-2/3"
                />
                {isUpdated && <Text className="ml-2 text-sm font-extralight italic">Updated</Text>}
              </div>
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>City:</Text>
                <Text>{state.city || "N/A"}</Text>
              </div>
              <div className="flex gap-x-2 text-base text-gray-700 font-semibold mb-2">
                <Text strong>State:</Text>
                <Text>{state.locationState || "N/A"}</Text>
              </div>
            </div>
          </Card>
        </Space>
      </div>
    </Spin>
  );
};