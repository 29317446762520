import { useMutation } from "@apollo/react-hooks";
import { Drawer, Popconfirm, Spin, Tag, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";
import { setSearchText } from "../../store/slices/auth.slice";
import { useNavigate } from "react-router-dom";
import { ADD_OR_REMOVE_POLL, ADD_VOTE, REMOVE_POLL } from "../../graphql/models";
import { successNotify, warnNotify } from "../../util";
import { AddSuggession } from "../CreatePoll/AddSuggession";
import { PollCardDrawer } from "./PollCardDrawer";
import { PollOption } from "./PollOptions";
import { RestrictionTag } from "./RestrictionTag";
import { sanitizeQuestion } from "../../util";

interface pollCardInter {
  data?: any;
  totalVote?: any;
  refetch?: Function;
}

export const PollCard = ({ data, totalVote, refetch }: pollCardInter) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { user, page } = useSelector((state: any) => state?.auth);
  const dispatch = useAppDispatch();
  const { tags, options } = data || {};
  const isMine = user?._id !== undefined ? user?._id === data?.user?._id : false;
  const isPollVoted = options.find(({ votes }) => votes.find((item) => item.user?._id === user?._id))?._id;
  const [addVoteMutation, { loading }] = useMutation(ADD_VOTE);

  const onAddVote = async (optionId: string) => {
    try {
      if (optionId === isPollVoted) return;
      else if (!user?._id) {
        navigate("/login");
        return;
      } else if (data?.user?._id === user._id) return;
      if (data?.locationRestriction) {
        const normalizeValue = (value) => (value ? value.trim() : null);
        const restrictedCity = normalizeValue(data.locationRestriction.city);
        const restrictedState = normalizeValue(data.locationRestriction.state);

        if (restrictedCity && restrictedState) {
          if (user.city !== restrictedCity || user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of ${restrictedCity}, ${restrictedState}`);
            return;
          }
        } else if (!restrictedCity && restrictedState) {
          if (user.locationState !== restrictedState) {
            warnNotify(`This poll is only for residents of the state of ${restrictedState}`);
            return;
          }
        } else if (restrictedCity && !restrictedState) {
          if (user.city !== restrictedCity) {
            warnNotify(`This poll is only for residents of ${restrictedCity}`);
            return;
          }
        }
      }
      if (data?.genderRestriction && data?.genderRestriction.gender) {
        if (user?.gender !== data.genderRestriction?.gender) {
          warnNotify(`This poll is only for ${data?.genderRestriction?.gender}s`);
          return;
        }
      }
      if (data?.ageRangeRestriction) {
        if (data.ageRangeRestriction.minAge != null && data.ageRangeRestriction.maxAge != null) {
          if (user?.age < data.ageRangeRestriction?.minAge || user?.age > data.ageRangeRestriction?.maxAge) {
            warnNotify(
              `This poll is only for people between the ages of ${data?.ageRangeRestriction?.minAge} and ${data?.ageRangeRestriction?.maxAge}`
            );
            return;
          }
        }
      }
      const {
        data: { AddVote },
      } = await addVoteMutation({
        variables: {
          optionId,
          pollId: data._id,
        },
      });
      if (AddVote.success) {
        // successNotify(AddVote.message);
        refetch();
      } else {
        warnNotify(AddVote.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [removePollMutation] = useMutation(REMOVE_POLL);
  const onRemovePoll = async (e) => {
    e.stopPropagation();
    try {
      const {
        data: { RemovePoll },
      } = await removePollMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (RemovePoll.success) {
        successNotify(RemovePoll.message);
        refetch();
      } else {
        warnNotify(RemovePoll.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const [addOrRemoveFollowerMutation] = useMutation(ADD_OR_REMOVE_POLL);
  const onAddOrRemoveFollower = async (e) => {
    e.stopPropagation();
    try {
      const {
        data: { AddOrRemoveFollower },
      } = await addOrRemoveFollowerMutation({
        variables: {
          pollId: data._id,
        },
      });
      if (AddOrRemoveFollower.success) {
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const filterByTag = (value) => {
    dispatch(setSearchText({ searchTag: value }));
  };

  const generateQuestionId = (question, locationRestriction, genderRestriction, ageRangeRestriction) => {
    const cityState = locationRestriction.city && locationRestriction.state 
      ? `${locationRestriction.city}-${locationRestriction.state}` 
      : "";
    const gender = genderRestriction.gender || "";
    const ageRange = ageRangeRestriction.minAge && ageRangeRestriction.maxAge 
      ? `${ageRangeRestriction.minAge}-${ageRangeRestriction.maxAge}-years` 
      : "";
  
    const formattedQuestion = sanitizeQuestion(question);
    
    // Build the formattedRestrictions string based on available data
    let formattedRestrictions;
    if (cityState && gender && ageRange) {
      formattedRestrictions = `${cityState}/${gender}/${ageRange}`;
    } else if (cityState && gender) {
      formattedRestrictions = `${cityState}/${gender}`;
    } else if (cityState && ageRange) {
      formattedRestrictions = `${cityState}/${ageRange}`;
    } else if (gender && ageRange) {
      formattedRestrictions = `${gender}/${ageRange}`;
    } else if (cityState) {
      formattedRestrictions = cityState;
    } else if (gender) {
      formattedRestrictions = gender;
    } else if (ageRange) {
      formattedRestrictions = ageRange;
    } else {
      formattedRestrictions = "";
    }
  
    // If restrictions exist, append them to the question, otherwise just return the question
    if (formattedRestrictions) {
      return `${formattedQuestion}/${formattedRestrictions}`;
    } else {
      return formattedQuestion;
    }
  };
  
  // Usage for handling redirects
  const handleRedirect = () => {
    const sanitizedQuestion = generateQuestionId(
      data?.question,
      data?.locationRestriction,
      data?.genderRestriction,
      data?.ageRangeRestriction
    );
  
    navigate(`/poll/${data?.category}/${sanitizedQuestion}`, {
      state: { id: data._id, page },
    });
  };

  const isFollowing = data.followers.some((item) => item?._id === user?._id);

  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-4">
      <Spin spinning={loading}>
        <Drawer open={visible} onClose={() => setVisible(false)} width="100VW">
          <PollCardDrawer data={data} totalVote={data.totalVote} refetch={refetch} />
        </Drawer>
        <div
          className="bg-white rounded-md shadow-xl hover:shadow-2xl p-5 border relative"
          // id="poll-card-parent"
          onClick={handleRedirect}
        >
          {/* <Link
            to={`/poll/${data?.question?.replace(/ /g, "-").toLowerCase()}`}
          > */}
          <div className="absolute top-3 right-2" id="poll-card-icon">
            {isMine && (
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                okButtonProps={{ className: "danger", danger: true }}
                onConfirm={onRemovePoll}
              >
                <i className="far fa-trash-alt text-red-600 mr-2" onClick={(e) => e.stopPropagation()}></i>
              </Popconfirm>
            )}

            {!isMine && user?._id && (
              <span className="text-[#7C83C8] cursor-pointer" onClick={onAddOrRemoveFollower}>
                <Tooltip placement="top" title={isFollowing ? "Unfollow" : "Follow"}>
                  {isFollowing ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
                </Tooltip>
              </span>
            )}
          </div>
          <div className="text-base  md:text-lg lg:text-2xl text-gray-700 mb-3">
            <div dangerouslySetInnerHTML={{ __html: data?.question }}></div>
          </div>
          <div className="text-base md:text-xs lg:text-xs text-gray-500 mb-3">Votes: {totalVote}</div>
          <div className="flex flex-col">
            <div className="flex">
              {tags?.map(({ _id, text }) => (
                <Tag
                  key={_id}
                  className="mb-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    filterByTag(text);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {text}
                </Tag>
              ))}
            </div>
            <div className="flex mb-4">
              <RestrictionTag
                content={
                  data?.locationRestriction &&
                  (() => {
                    const { city, state } = data.locationRestriction;
                    return city && state && state !== "null" && state !== "" ? `${city}, ${state}` : city || state;
                  })()
                }
              />

              <RestrictionTag content={data?.genderRestriction?.gender && `${data.genderRestriction.gender}`} />

              <RestrictionTag
                content={
                  data?.ageRangeRestriction &&
                  data.ageRangeRestriction?.maxAge != null &&
                  data.ageRangeRestriction?.minAge != null &&
                  (() => {
                    const { minAge, maxAge } = data.ageRangeRestriction;
                    return minAge && maxAge
                      ? `${minAge}yrs. - ${maxAge}yrs.`
                      : minAge
                      ? `minimum age: ${minAge}`
                      : `maximum age: ${maxAge}`;
                  })()
                }
              />
            </div>
          </div>
          {options?.map((op) => (
            <PollOption
              {...op}
              key={op._id}
              isMine={isMine}
              isPollVoted={isPollVoted}
              totalVote={totalVote}
              onAddVote={onAddVote}
              refetch={refetch}
              pollId={data._id}
            />
          ))}
          {data?.suggestionable && !isMine && user?._id && data?.user?._id && (
            <AddSuggession refetch={refetch} pollId={data._id} />
          )}
          {/* </Link> */}
        </div>
      </Spin>
    </div>
  );
};
