import { Tag } from "antd";

interface RestrictionTagProps {
  label?: string;
  content: string | null | undefined;
  ariaLabel?: string;
}

export const RestrictionTag: React.FC<RestrictionTagProps> = ({ label, content, ariaLabel }) => {
  if (!content) return null;

  const accessibilityLabel = ariaLabel || (label ? `${label}: ${content}` : content);

  return (
    <Tag className="bg-red-100 border border-red-500 hover:bg-red-200" aria-label={accessibilityLabel}>
      <div className="text-base md:text-xs lg:text-xs text-red-700 hover:text-red-800 mb-1">
        {label ? `${label}: ${content}` : content}
      </div>
    </Tag>
  );
};
