import gql from "graphql-tag";

export const ADD_COMMENT = gql`
  mutation AddComment($commentInput: CommentInput) {
    AddComment(commentInput: $commentInput) {
      code
      success
      message
      count
      comments {
        body
        _id
        userId
        parentId
        username
        updatedAt
        pollId
        likes
      }
    }
  }
`;

export const FETCH_COMMENT = gql`
  query FetchComment($pollId: ID, $questionId: String) {
    FetchComment(pollId: $pollId, questionId: $questionId) {
      message
      code
      success
      count
      comments {
        body
        _id
        userId
        parentId
        username
        updatedAt
        pollId
        likes
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($commentID: ID, $pollId: ID!) {
    DeleteComment(commentID: $commentID, pollId: $pollId) {
      code
      success
      message
      count
      comments {
        body
        _id
        userId
        parentId
        username
        updatedAt
        pollId
        likes
      }
    }
  }
`;

export const EDIT_COMMENT = gql`
  mutation EditComment($commentID: ID, $updatedComment: String, $pollId: ID!, $likes: ID) {
    EditComment(commentID: $commentID, updatedComment: $updatedComment, pollId: $pollId, likes: $likes) {
      code
      success
      message
      count
      comments {
        body
        _id
        userId
        parentId
        username
        updatedAt
        pollId
        likes
      }
    }
  }
`;
