import { useState } from "react";

interface AddOptionProps {
  options: Array<string>;
  setOptions: React.Dispatch<React.SetStateAction<Array<string>>>;
  onBlur: () => void;
}

export const AddOption = ({ options, setOptions, onBlur }: AddOptionProps) => {
  const [hideInput, setHideInput] = useState(true);
  const [answerText, setAnswerText] = useState("");

  const onAddOption = () => {
    if (!answerText) {
      return;
    }
    setOptions([...options, answerText]);
    setHideInput(true);
    setAnswerText("");
    onBlur();
  };

  const onCancel = () => {
    setHideInput(true);
    setAnswerText("");
    onBlur();
  };

  const handleKeyDown = (event) => {
    if (answerText && event.key === "Enter") {
      setOptions([...options, answerText]);
      setAnswerText("");
      onBlur();
    }
    if (event.keyCode === 27) {
      onCancel();
    }
  };

  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-4">
      {hideInput ? (
        <div
          className="p-3 bg-white cursor-pointer border-[2px] border-gray-400 rounded-lg font-bold text-center"
          onClick={() => setHideInput(false)}
        >
          + Add Answer
        </div>
      ) : (
        <div className="relative">
          <input
            className="w-full border border-gray-400 px-3 py-3 rounded-lg"
            value={answerText}
            onChange={(e) => setAnswerText(e.target.value)}
            placeholder="Type answer"
            autoFocus
            onKeyDown={handleKeyDown}
          />
          <div className="absolute w-[100px] -top-[20px] right-0 text-end">
            <i className="fas fa-times mr-2 p-1 cursor-pointer text-red-600" onClick={onCancel}></i>
            <button className="disabled:opacity-80" onClick={onAddOption} disabled={!answerText}>
              <i className="fas fa-check p-1"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
