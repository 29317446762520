import gql from "graphql-tag";

export const FETCH_FAQ = gql`
  query FetchFAQ($limit: Int, $offset: Int) {
    FetchFAQ(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        faq {
          _id
          qua
          ans
        }
      }
    }
  }
`;
